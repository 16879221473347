@import "~assets/sass/_mixins";

.download-results {
    .indicator {
        display: inline-block;
        max-width: 28px;
        max-height: 28px;
        padding: 4px 0;
        margin: 0 5px 0 0;
    }

    .details {
        font-size: pxToRem(14);
    }
}
