@import "~assets/sass/_mixins";
@import "~assets/sass/_variables";

.cookies-banner {
    background-color: #3a3a3a;
    min-height: 65px;
    color: $white;
    padding: 15px;
    border-radius: 8px;

    &--bottom {
        bottom: 0;
    }

    &--overlay {
        position: fixed;
        right: 180px;
        left: 180px;
        z-index: $fade-z-index + 1;

        @include laptop {
            right: 0;
            left: 0;
        }
    }

    .cookies-content {
        font-size: pxToRem(14);

        &--link {
            margin-left: 10px;

            @include themify($themes) {
                color: themed("secondaryColor");
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}
