@import "~assets/sass/_variables";

.component-model-dashboard-default {
    &__body-container {
        padding: 0 30px;
        margin: 20px 0;

        &.in-progress {
            opacity: 0.5;
            pointer-events: none;
        }

        > div:not(:first-child) {
            margin-top: 20px;
        }

        .calculated-results-panel {
            min-height: $calculated-results-panel-height;
        }

        .model-log-panel {
            min-height: $model-log-panel-height;
        }

        .model-variables-panel {
            height: $model-variables-panel-height;
        }

        .model-inputs-panel {
            height: $model-inputs-panel-height;
        }

        .utility-forecast-panel {
            height: $utility-forecast-panel-height;
        }
    }
}
