@import "~assets/sass/_mixins";

.form-wrap-side-container {
    background-image: url("https://files.insight.visiondsm.com/public/Vision-Background-LoadMAP.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;

    &:before {
        content: "";
        background-color: #1a143a9c;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &__vision-logo {
        background-image: url("https://files.insight.visiondsm.com/public/Vision-Icon-White.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        width: 162px;
        height: 162px;
        z-index: 2;
    }

    &__tagline {
        max-width: 590px;
        font-family: var(--ids-semantic-font-font-family-label);
        font-weight: 600;
        font-size: 44px;
        line-height: 60px;
        color: var(--ids-semantic-ink-color-neutral-accent-ondark);
        text-align: center;
        margin-top: 50px;
        z-index: 2;

        @include laptop {
            font-size: 34px;
            margin-top: 20px;
        }
    }
}
