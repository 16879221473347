@import "~assets/sass/_mixins";

.project-dashboard-default {
    &__body-container {
        padding: 0 30px;
        margin: 20px 0;

        > div:not(:first-child) {
            margin-top: 20px;
        }

        .panels-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .project-variables-panel,
            .project-labels-panel {
                height: $project-variables-panel-height-large;

                @include calc(flex-basis, "50% - 10px");
            }

            @include laptop {
                flex-direction: column;
                justify-content: flex-start;

                .project-variables-panel,
                .project-labels-panel {
                    height: $project-variables-panel-height-small;
                    flex-basis: auto;
                }

                .project-labels-panel {
                    margin-top: 20px;
                }
            }
        }

        .project-log-panel {
            min-height: $project-log-panel-height;
        }

        .project-details-panel {
            min-height: $project-details-panel-height;
        }

        .project-study-case-panel {
            min-height: $project-study-case-panel-height;
        }

        .models-panel {
            height: $models-panel-height;
        }

        .market-profile-snapshot-panel {
            height: $market-profile-snapshot-panel-height;
        }
    }
}

.split-view {
    .project-dashboard-default {
        .panels-row {
            flex-direction: column;
            justify-content: flex-start;

            .project-variables-panel,
            .project-labels-panel {
                min-height: unset;
                height: $project-variables-panel-height-small;
                flex-basis: auto;
            }

            .project-labels-panel {
                margin-top: 20px;
            }
        }
    }
}
