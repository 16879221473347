@import "~@appliedenergygroup/ids-theme-aeg/assets/aeg/tokens/_variables.css";
@import "~@appliedenergygroup/ids-theme-aeg/assets/aeg/tokens/aeg-fonts.css";
@import "./assets/sass/_variables";
@import "./assets/sass/_mixins";
@import "./assets/sass/_functions";
@import "./assets/sass/_fonts";
@import "./assets/sass/_flexbox";
@import "./assets/sass/_icons";
@import "./assets/sass/_viIcons";
@import "./assets/sass/_grid";
@import "./assets/sass/_animations";
@import "./assets/sass/_toast";

/*
    HTML element styles
*/

* {
    margin: 0;
    padding: 0;

    @include box-sizing(border-box);
    @include webkit-scrollbar;

    -webkit-overflow-scrolling: touch;

    &:focus {
        outline: none;
    }

    &[hidden] {
        display: none !important;
    }
}

html,
body {
    height: 100%;
    overflow: hidden;
}

html {
    min-width: $site-min-width;
    font-family: var(--ids-semantic-font-font-family-body);
    font-size: $base-font-size; // TODO: Replace with font size 16px
    font-weight: var(--ids-semantic-font-fixed-body3-regular-font-weight);
    color: var(--ids-core-color-neutral-950);
    overflow-x: auto;
}

#root {
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;

    @include themify($themes) {
        background: themed("appBackgroundColor");
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: pxToRem(32);
}

h2 {
    font-size: pxToRem(24);
}

h3 {
    font-size: pxToRem(18);
}

p {
    line-height: 22px;

    .content-header + & {
        margin-top: 10px;
    }
}

ul {
    list-style: none;
}

a,
.link {
    text-decoration: none;
    cursor: pointer;

    @include themify($themes) {
        color: themed("primaryColor");
    }
}

/*
    Globally defined styles
*/

// Text styles

.text-bold {
    font-weight: 700;
}

.text-italic {
    font-style: italic;
}

.text-center {
    text-align: center;
}

.text-underline {
    text-decoration: underline;
}

.content-header {
    font-weight: 700;

    &.sticky {
        position: sticky;
        top: 0;
        z-index: 2;
        min-height: 20px;
        background: white;
    }
}

.button-container {
    min-width: 120px;
}

// Layout

.no-scroll {
    overflow: hidden;
}

.with-scroll {
    height: 100%;
    overflow: auto;
}

.one-third {
    @include calc(width, "100% / 3");
}

.one-fifth {
    @include calc(width, "100% / 5");
}

// Spacing

.margin-left {
    margin-left: 20px;
}

.margin-left-small {
    margin-left: 10px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.margin-bottom-small {
    margin-bottom: 10px;
}

// Misc

.required:after {
    content: "*";
    margin-left: 3px;
    line-height: 14px;
    color: $red-medium;
}

.optional:after {
    content: "(Optional)";
    margin-left: 3px;
    color: $charcoal-subtle-ink;
}

.clickable {
    cursor: pointer;
}

.remove-background {
    background: none;
}
