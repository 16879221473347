@import "~assets/sass/_mixins";

.sidenav-body {
    padding: 20px;

    &.flex-one {
        @include ie {
            flex: 1 0 auto;
            height: 0;
        }
    }

    &.no-padding {
        padding: 0;
    }
}

.sidenav-header + .sidenav-body {
    padding-top: 0;
}
