@import "~assets/sass/_mixins";

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.54);
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    z-index: $fade-z-index + 3;

    &.modal-styled {
        .modal {
            width: 100%;
            min-width: inherit;
            max-width: inherit;

            &.modal-sm {
                width: 470px;
            }

            &.modal-md {
                width: 650px;
            }

            &.modal-lg {
                max-width: 850px;
            }

            &.modal-fill {
                max-width: 90vw;
            }

            &.modal-with-grid {
                @include ie {
                    overflow: auto;
                }

                .modal-body {
                    overflow: hidden;

                    > .flex-column {
                        height: 100%;
                    }

                    .grid-list {
                        @include data-grid-sticky-header;

                        @include ie {
                            overflow: hidden;
                        }
                    }

                    .grid-list-row.details {
                        margin-right: 15px;
                        margin-left: 15px;

                        @include calc(width, "100% - 30px");

                        .fill-width {
                            width: 100%;
                        }
                    }
                }
            }

            .modal-content {
                .modal-body {
                    margin: 0;

                    &.highlight-custom-list {
                        .custom-list {
                            background: rgba(255, 255, 255, 0.7);

                            .list-header-row {
                                background: rgba(0, 0, 0, 0.03);
                            }
                        }
                    }

                    .with-scroll {
                        /*
                            Only supported in WebKit-based and
                            Blink-based browsers.
                            This is a temporary solution.
                        */
                        overflow: overlay;
                    }

                    .modal-padding {
                        padding: 0 20px;

                        /*
                            Only child when modal does not
                            have any action buttons.
                        */
                        &:only-child {
                            padding: 0 20px 20px 20px;
                        }
                    }

                    .modal-actions {
                        padding: 20px;
                    }
                }

                .modal-footer {
                    margin: 15px 0 0 0;
                    padding: 0;

                    &.center {
                        justify-content: center;
                    }
                }
            }

            &.modal-centered-action-buttons {
                .action-buttons {
                    justify-content: center;
                }
            }
        }
    }

    .modal {
        outline: none;
        display: flex;
        align-self: center;
        margin: auto;
        width: auto;
        height: auto;
        min-width: 20vw;
        max-width: 80vw;
        min-height: auto;
        max-height: 90vh;

        // Need for Dropdowns to go over modal borders
        overflow: visible;
        border-radius: 12px;

        @include box-shadow-common($shadow-panel);

        &.modal-with-grid,
        &.modal-wide {
            min-width: 50vw;
        }

        .modal-content {
            background-color: var(--ids-semantic-background-color-neutral-accent-default-onlight);
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: 100%;

            .modal-header {
                font-weight: 500;
                font-size: pxToRem(18);
                line-height: 16px;
                display: flex;
                align-items: center;
                padding: 20px;
            }

            .modal-body {
                font-size: pxToRem(14);
                max-height: 100%;
                white-space: pre-line;

                // Need for Dropdowns to go over modal borders
                overflow: visible;
            }

            .modal-footer {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 0 20px;

                .btn:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        &.with-scroll {
            overflow: auto;

            .modal-body {
                overflow: auto;
            }
        }

        &.modal-select-from-grid {
            width: 30vw;

            &--wide {
                width: 80vw;
            }

            .grid-list-row-container {
                max-height: 300px;
                overflow: auto;
            }

            .modal-body {
                margin-bottom: 0;
                padding-bottom: 2px;
                min-height: 200px;
            }
        }
    }
}
