.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-column-reverse {
    flex-direction: column-reverse;
}

.flex-auto {
    flex: auto;
}

.flex-wrap {
    @include flex-wrap(wrap);
}

.flex-no-wrap {
    @include flex-wrap(nowrap);
}

.flex-one {
    flex: 1;
}

.flex-one-in-column {
    flex: 1;
    height: 0;
}

.flex-one-in-row {
    flex: 1 1 0;

    @include ie {
        width: 0;
    }
}

.flex-grow {
    flex-grow: 1;
}

.flex-center {
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100%;
}

.flex-margin {
    margin: 10px;
}

.flex-padding {
    padding: 10px;
}

.full-height {
    height: 100vh;
}

.fill-height {
    height: 100%;
}

.fill-width {
    width: 100%;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-stretch {
    align-items: stretch;
}

.align-self-start {
    align-self: flex-start;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-evenly {
    justify-content: space-evenly;
}

.no-shrink {
    flex-shrink: 0;
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-2 {
    gap: 0.5rem !important;

    .one-third {
        @include calc(width, "100% / 3 - 0.5rem");
    }

    .one-fifth {
        @include calc(width, "100% / 5 - 0.5rem");
    }
}

.gap-3 {
    gap: 1rem !important;
}

.gap-4 {
    gap: 1.5rem !important;
}

.gap-5 {
    gap: 3rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.pl-4 {
    padding-left: 1.5rem !important;
}

.pr-4 {
    padding-right: 1.5rem !important;
}

.px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

@include ie {
    .flex-one {
        flex: 1 0 auto;
    }

    .flex-one-in-column {
        flex: 1 0 auto;
        height: 0;
    }
}

@include firefox {
    .flex-one {
        flex: 1 0 auto;
    }

    .flex-one-in-column {
        flex: 1 0 auto;
        height: 0;
    }
}
