@import "~assets/sass/_mixins";

.project-details {
    &__field {
        @include calc(width, "100% / 3");

        &:nth-child(even) {
            padding: 0 20px;

            @include desktop-large {
                padding: 0 60px;
            }
        }
    }

    &__actions {
        margin: 20px 0 0 0;
    }
}
