@import "~assets/sass/_mixins";

.home-dashboard-default {
    .left-container {
        min-width: 320px;

        .client-list-panel {
            border: none;
            border-radius: 0;

            @include calc(height, "100% - 44px");
        }
    }

    .right-container {
        padding: 25px 40px 55px 25px;

        @include laptop {
            padding: 15px;
        }
    }

    &.home-dashboard-stakeholder {
        .client-list-panel {
            height: 100%;
        }

        .project-list {
            .list-item-row {
                height: 54px;
            }
        }
    }
}
