@import "~assets/sass/_variables";

.icon-triangle {
    width: 0;
    height: 0;

    &.upwards {
        border-right: 3px solid transparent;
        border-left: 3px solid transparent;

        border-bottom: 3px solid $grey-dark;
    }

    &.rightwards {
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;

        border-left: 3px solid $grey-dark;
    }

    &.downwards {
        border-right: 3px solid transparent;
        border-left: 3px solid transparent;

        border-top: 3px solid $grey-dark;
    }

    &.leftwards {
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;

        border-right: 3px solid $grey-dark;
    }
}
