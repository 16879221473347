@import "~assets/sass/_variables";

.project-dashboard-client {
    &__body-container {
        padding: 0 30px;
        margin: 20px 0;

        > div:not(:first-child) {
            margin-top: 20px;
        }

        .project-log-panel {
            min-height: $project-log-panel-height;
        }

        .project-details-panel {
            min-height: $project-details-panel-height;
        }

        .project-study-case-panel {
            min-height: $project-study-case-panel-height;
        }

        .models-panel {
            height: $models-panel-height;
        }

        .market-profile-snapshot-panel {
            height: $market-profile-snapshot-panel-height;
        }

        .project-labels-panel {
            height: $project-variables-panel-height-client;
        }
    }
}
