@import "~assets/sass/_mixins";

.accordion-root {
    .accordion-header {
        color: var(--ids-semantic-ink-color-neutral-subtle-onlight);
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        padding: 15px 5px 15px 15px;

        &.is-hovered {
            background-color: var(--ids-semantic-background-color-brand-a-subtlest-hover);
        }

        &__button {
            width: -webkit-fill-available;
            background-color: transparent;
            border: transparent;
            cursor: pointer;
        }

        &__shevron {
            float: left;
            margin-top: 14px;
            cursor: pointer;

            @include rotate(0);

            &.expanded {
                @include rotate(90deg);
            }

            .shevron-keyboard-arrow-right {
                svg {
                    fill: var(--ids-semantic-ink-color-brand-a-accent);
                }
            }
        }

        &__no_shevron {
            padding-left: 20px;
        }
    }

    .accordion-item {
        border-bottom: 1px solid #dfe6e9;
        position: relative;

        &__link {
            display: block;

            span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:hover {
                span {
                    white-space: break-spaces;
                }
            }
        }

        &.disabled {
            pointer-events: none;
        }
    }

    .accordion-item + .accordion-item {
        border-top: none;
    }

    .focus-ring {
        border: 2px solid var(--ids-semantic-focus-ring-color-brand-a);
    }
}
