@import "~assets/sass/_mixins";

.icon-loading {
    &__items-container {
        padding: 0 4px;
    }

    &__item {
        background-color: rgba(0, 0, 0, 0.54);
        display: inline-block;
        width: 6px;
        height: 6px;
        animation: sk-bouncedelay 1.7s infinite ease-in-out both;
        border-radius: 100%;

        &:nth-child(1) {
            animation-delay: -0.6s;
        }

        &:nth-child(2) {
            margin: 0 0 0 5px;
            animation-delay: -0.4s;
        }

        &:nth-child(3) {
            margin: 0 0 0 5px;
            animation-delay: -0.2s;
        }
    }

    &__label {
        font-size: pxToRem(12);
        line-height: 14px;
        margin: 0 0 0 5px;

        @include themify($themes) {
            color: themed("themeColorLocked");
        }
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
        opacity: 0;
    }

    40% {
        transform: scale(1);
        opacity: 1;
    }
}
