.with-icon {
    &.alert-error-empty:before {
        content: $icon-alert-error-empty;
    }

    &.arrow-thin-left:before {
        content: $icon-arrow-thin-left;
    }

    &.assessment-empty:before {
        content: $icon-assessment-empty;
    }

    &.circle-small-empty:before {
        content: $icon-circle-small-empty;
    }

    &.chart-pie-circular-empty {
        &.icon-after-label:after {
            content: $icon-chart-pie-circular-empty;
        }

        &:not(.icon-after-label):before {
            content: $icon-chart-pie-circular-empty;
        }
    }

    &.folder-closed-empty:before {
        content: $icon-folder-closed-empty;
    }

    &.folder-open-empty:before {
        content: $icon-folder-open-empty;
    }

    &.fullscreen:before {
        content: $icon-fullscreen;
    }

    &.lightbulb-empty:before {
        content: $icon-lightbulb-empty;
    }

    &.note-empty:before {
        content: $icon-note-empty;
    }

    &.octagon-exclamation-empty:before {
        content: $icon-octagon-exclamation-empty;
    }

    &.octagon-exclamation-filled:before {
        content: $icon-octagon-exclamation-filled;
    }

    &.pause:before {
        content: $icon-pause;
    }

    &.powerbi-reports:before {
        content: $icon-chart-multiple;
    }

    &.repeat-empty:before {
        content: $icon-repeat-empty;
    }

    &.reply:before {
        content: $icon-reply;
    }

    &.run-filled:before {
        content: $icon-run-filled;
    }

    &.step-back {
        @include rotate(180deg, x);

        &:before {
            content: $icon-reply;
        }
    }

    &.swap-horizontal {
        &.icon-after-label:after {
            content: $icon-swap-horizontal;
        }

        &:not(.icon-after-label):before {
            content: $icon-swap-horizontal;
        }
    }

    &.upload:before {
        content: $icon-upload;
    }

    &.vi-upload:before {
        content: $icon-vi-upload;
    }
}
