@import "~assets/sass/_mixins";

.component-model-dashboard-header {
    background-color: $white;
    padding: 50px 30px 15px 30px;

    @include box-shadow-common($shadow-emboss);

    &__top-container {
        margin-bottom: 10px;
    }

    &__model {
        color: var(--ids-semantic-ink-color-neutral-subtle-onlight);
        font-family: var(--ids-semantic-font-font-family-label);
        font-size: pxToRem(18);
    }

    &__title {
        font-family: var(--ids-semantic-font-font-family-label);
        font-weight: 500;
        font-size: pxToRem(21);
        line-height: 24px;
    }

    &__middle-container {
        margin-bottom: 10px;
    }

    &__model-details-container {
        margin-right: 20px;
    }

    &__model-details {
        margin-bottom: 10px;
    }

    &__model-actions-container {
        display: flex;
        align-items: center;

        @include laptop {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__status-container {
        @include laptop {
            margin-bottom: 5px;
        }
    }

    &__duplicate-container {
        margin-left: 10px;

        @include laptop {
            margin-left: 0;
        }
    }

    .import-error-panel,
    .calculation-error-panel,
    .import-data-panel {
        padding-right: 20px;

        .info-panel {
            margin-top: 0;
        }
    }

    .switcher-label {
        font-size: pxToRem(14);
    }

    &__progress-bar {
        .indeterminate-progress-bar {
            .top-container {
                color: var(--ids-semantic-ink-color-neutral-accent-onlight);
                font-family: var(--ids-semantic-font-font-family-label);
                font-weight: var(--ids-semantic-font-fixed-label2-regular-font-weight);
                font-size: pxToRem(14);
                line-height: 24px;
            }
        }
    }

    &__progress-bar-top-element {
        font-weight: var(--ids-semantic-font-fixed-label2-regular-font-weight);
    }
}
