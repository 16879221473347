/*
    --------------------------------------------------------------
        UI Library
    --------------------------------------------------------------
*/

// 1 - Defines
//
// Font sizes

// @todo: set the default value of root element to 16px
// when all HTML elements are replaced with IDS components
$base-font-size: 14px;

// Application properties

$site-min-width: 1280px;
$site-max-width: 1600px;

$scrollbar-size: 10px;
$scrollbar-thin-size: 3px;

$progress-bar-height: 16px;

$vertical-fade-min-height: 20px;
$fade-z-index: 100;

$new-territory-column-width: (
    alias: 250px,
    code: 100px,
    remove: 50px,
);

$new-territory-column-padding: 10px;

// Project Dashboard panel heights

$project-log-panel-height: 410px;
$project-details-panel-height: 300px;
$project-study-case-panel-height: 575px;
$models-panel-height: 490px;
$market-profile-snapshot-panel-height: 585px;
$project-variables-panel-height-small: 460px;
$project-variables-panel-height-client: 570px;
$project-variables-panel-height-large: 770px;

// Project Stakeholder Dashboard panel heights

$project-stakeholder-dashboard-panel-height: 410px;

// Model Dashboard panel heights

$calculated-results-panel-height: 165px;
$model-log-panel-height: 410px;
$model-study-case-panel-height: 645px;
$model-variables-panel-height: 460px;
$model-inputs-panel-height: 540px;
$utility-forecast-panel-height: 530px;

// Standalone Report Dashboard panel heights

$report-log-panel-height: 410px;
$embedded-panel-height: 640px;

// 2 - Fonts

$font-family-iconoteka: "iconoteka";

// 3 - Colors

$white: #ffffff;
$grey: #f6f6f7;
$grey-light: #dfe6e9;
$grey-medium: #b2b2ba;
$grey-dark: #636e72;
$grey-white: #f8f9f9;
$grey-white-medium: #cdcdd5;
$grey-white-dark: #97979f;

$red-medium: #a0180c;
$red-dark: #7a0000;
$red-white: #ffebec;
$red-gradient: linear-gradient(to bottom, rgba(160, 24, 12, 0.01), rgba(160, 24, 12, 0.08));

$green: #00a06d;
$green-gradient: linear-gradient(to bottom, rgba(74, 222, 166, 0.01), rgba(74, 222, 166, 0.08));

$yellow-dark: #cca645;
$yellow-white: #fffceb;
$yellow-gradient: linear-gradient(to bottom, rgba(231, 197, 0, 0.01), rgba(231, 197, 0, 0.08));

$gray-50: #f9f9ff;
$gray-100: #e8e8f0;

$charcoal-subtle-ink: #7e818e;
$charcoal-accent-ink: #14161b;
$charcoal-accent-border: #363b45;
$charcoal-faint-bg: #f6f6f7;

$red-700: #bf0000;

$orange-accent-ink: #b52d00;
$orange-accent-border: #f26549;
$orange-faint-bg: #ffece9;

$yellow-accent-ink: #695900;
$yellow-accent-border: #b19700;
$yellow-faint-bg: #fffced;

$green-accent-ink: #005f42;
$green-accent-border: #00ac7a;
$green-faint-bg: #e9fff7;

$blue-accent-ink: #1e13a6;
$blue-accent-border: #1e13a6;
$blue-faint-bg: #edf1ff;

$purple-accent-ink: #7140d1;
$purple-accent-border: #8d56ff;
$purple-faint-bg: #f1ecff;

// 4 - Shadows

// General

$shadow-outer: 0 1px 8px 0 rgba(0, 0, 0, 0.25);
$shadow-outer-hover: 0 1px 4px 0 rgba(0, 0, 0, 0.25);

$shadow-emboss: 0px -1px 0px 0px $grey-light inset;

// Buttons

$shadow-button-primary-hover: inset 0px -3px 20px rgba(0, 0, 0, 0.25);
$shadow-button-secondary-hover: inset 0px 0px 10px rgba(23, 16, 163, 0.4), inset 1px 1px 2px $grey-light;

// Windows

$shadow-windows-outer: 0px 0px 10px 2px rgba(25, 23, 43, 0.05);

$shadow-panel: 0px 4px 40px 0px rgba(0, 0, 0, 0.04), 0px 2px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 32px 0px rgba(0, 0, 0, 0.06);

// Other

$shadow-neumorphism: 6px 6px 8px #dddee1, -6px -6px 8px white; // used in LoginForm

// 5 - Icons from Iconoteka
//
// Icons coming from VisionDSM

$icon-add-create-plus-circle-filled: "\EB8F";
$icon-arrow-in-circle-left-empty: "\E809";
$icon-arrow-in-circle-right-empty: "\E80F";
$icon-arrow-thin-right: "\F127";
$icon-assignment: "\E8EB"; // Project icon
$icon-assignment-self: "\E8ED"; // Client Management icon
$icon-attachment: "\E8F6";
$icon-backup-restore-empty: "\E826";
$icon-bookmark-empty: "\E8FA";
$icon-bookmark-filled: "\E8F9";
$icon-calendar-empty: "\E8B5";
$icon-calendar-filled: "\E8B4";
$icon-cancel-clear-circle-highlight-off-empty: "\EBC4";
$icon-cancel-clear-circle-highlight-off-filled: "\EBC3";
$icon-checkbox-checked-filled: "\EBD6";
$icon-checkbox-unchecked-empty: "\EBD9";
$icon-check-circle-empty: "\EBD5";
$icon-check-circle-filled: "\EBD4";
$icon-check-done: "\F115";
$icon-circle-exclamation-filled: "\EB61";
$icon-circle-radio-button-checked-filled: "\EC36";
$icon-circle-radio-button-unchecked-empty: "\EBDB";
$icon-clear-close: "\F118";
$icon-create-empty: "\EBE5";
$icon-cut-scissors-filled: "\EBF2";
$icon-delete-trash-empty: "\EBF8";
$icon-delete-trash-filled: "\EBF7";
$icon-document-text: "\E914";
$icon-do-not-disturb-on-remove-circle-empty: "\EC00";
$icon-download: "\E82C";
$icon-exit-door-empty: "\E830";
$icon-eye-visibility-empty: "\EAC0";
$icon-hub-empty: "\E9D4"; // Avoided Costs icon
$icon-help-empty: "\EB77";
$icon-help-filled: "\EB76";
$icon-info-empty: "\EB7D";
$icon-link: "\E885";
$icon-open-new-window: "\E83D";
$icon-play-in-circle-filled: "\EA8B";
$icon-plus: "\EB90";
$icon-print: "\EA25";
$icon-search: "\EC3C";
$icon-settings-applications-empty: "\EC43";
$icon-shevron-in-circle-up-filled: "\E85B";
$icon-shevron-keyboard-arrow-left: "\F130";
$icon-shevron-keyboard-arrow-right: "\F132";
$icon-shevron-small-down-expand-more: "\F135";
$icon-shevron-small-up-expand-less: "\F13C";
$icon-swap-vertical: "\E874";
$icon-update-refresh: "\E87E";
$icon-view-module-empty: "\EC72";
$icon-view-stream-view-agenda-empty: "\EC76";
$icon-view-stream-view-agenda-filled: "\EC75";
$icon-warning-report-problem-empty: "\EB8E";
$icon-warning-report-problem-filled: "\EB8D";

// Vision Insight own icons

$icon-alert-error-empty: "\EB62";
$icon-arrow-thin-left: "\F124";
$icon-assessment-empty: "\E8E9";
$icon-chart-pie-circular-empty: "\EBD1";
$icon-circle-small-empty: "\EA69";
$icon-control-point-duplicate-empty: "\EBE1";
$icon-folder-closed-empty: "\E91C";
$icon-folder-open-empty: "\E920";
$icon-fullscreen: "\E835";
$icon-lightbulb-empty: "\EA6E";
$icon-note-empty: "\E8C3";
$icon-octagon-exclamation-empty: "\EB88";
$icon-octagon-exclamation-filled: "\EB87";
$icon-pause: "\EA87";
$icon-chart-multiple: "\EBCF"; // Power BI reports icon
$icon-repeat-empty: "\E841";
$icon-reply: "\E844";
$icon-run-filled: "\EA8B";
$icon-step-back: "\E844";
$icon-swap-horizontal: "\E873";
$icon-upload: "\E83F";
$icon-vi-upload: "\E9FE";

// 6 - Themes

$themes: (
    "default": (
        appBackgroundColor: $grey,
        primaryColor: #1e13a6,
        primaryBackgroundColor: $gray-50,
        secondaryColor: #f26549,
        secondaryBackgroundColor: rgba(242, 101, 73, 0.1),
        status1Color: #f26549,
        status1BackgroundColor: rgba(242, 101, 73, 0.1),
        status2Color: #5f3fd7,
        status2BackgroundColor: #faf5ff,
        status3Color: #19172b,
        status3BackgroundColor: rgba(25, 23, 43, 0.07),
        status4Color: #00a06d,
        status4BackgroundColor: #e9fff7,
        projectStatus1InkColor: $yellow-accent-ink,
        projectStatus1BorderColor: $yellow-accent-border,
        projectStatus1BackgroundColor: $yellow-faint-bg,
        projectStatus2InkColor: $blue-accent-ink,
        projectStatus2BorderColor: $blue-accent-border,
        projectStatus2BackgroundColor: $blue-faint-bg,
        projectStatus3InkColor: $green-accent-ink,
        projectStatus3BorderColor: $green-accent-border,
        projectStatus3BackgroundColor: $green-faint-bg,
        modelStatus1InkColor: $yellow-accent-ink,
        modelStatus1BorderColor: $yellow-accent-border,
        modelStatus1BackgroundColor: $yellow-faint-bg,
        modelStatus2InkColor: $blue-accent-ink,
        modelStatus2BorderColor: $blue-accent-border,
        modelStatus2BackgroundColor: $blue-faint-bg,
        modelStatus3InkColor: $purple-accent-ink,
        modelStatus3BorderColor: $purple-accent-border,
        modelStatus3BackgroundColor: $purple-faint-bg,
        modelStatus4InkColor: $charcoal-accent-ink,
        modelStatus4BorderColor: $charcoal-accent-border,
        modelStatus4BackgroundColor: $charcoal-faint-bg,
        modelStatus5InkColor: $orange-accent-ink,
        modelStatus5BorderColor: $orange-accent-border,
        modelStatus5BackgroundColor: $orange-faint-bg,
        modelStatus6InkColor: $green-accent-ink,
        modelStatus6BorderColor: $green-accent-border,
        modelStatus6BackgroundColor: $green-faint-bg,
        componentModelStatus1InkColor: $yellow-accent-ink,
        componentModelStatus1BorderColor: $yellow-accent-border,
        componentModelStatus1BackgroundColor: $yellow-faint-bg,
        componentModelStatus2InkColor: $blue-accent-ink,
        componentModelStatus2BorderColor: $blue-accent-border,
        componentModelStatus2BackgroundColor: $blue-faint-bg,
        componentModelStatus3InkColor: $green-accent-ink,
        componentModelStatus3BorderColor: $green-accent-border,
        componentModelStatus3BackgroundColor: $green-faint-bg,
        modelInputStatus1InkColor: $orange-accent-ink,
        modelInputStatus1BorderColor: $orange-accent-border,
        modelInputStatus1BackgroundColor: $orange-faint-bg,
        modelInputStatus2InkColor: $blue-accent-ink,
        modelInputStatus2BorderColor: $blue-accent-border,
        modelInputStatus2BackgroundColor: $blue-faint-bg,
        modelInputStatus3InkColor: $purple-accent-ink,
        modelInputStatus3BorderColor: $purple-accent-border,
        modelInputStatus3BackgroundColor: $purple-faint-bg,
        modelInputStatus4InkColor: $green-accent-ink,
        modelInputStatus4BorderColor: $green-accent-border,
        modelInputStatus4BackgroundColor: $green-faint-bg,
        selectionColor: #1d12a5,
        linkBackgroundColor: rgba(127, 89, 245, 0.15),
        calculationSecondaryColor: #3d26ba,
        welcomeBoardBackgroundColor: #19172b,
    ),
);

// 7 - Color maps
//
// Colors for Project statuses

$projectStatus: (
    proposed: (
        borderColor: "projectStatus1BorderColor",
        backgroundColor: "projectStatus1BackgroundColor",
        color: "projectStatus1InkColor",
    ),
    in-progress: (
        borderColor: "projectStatus2BorderColor",
        backgroundColor: "projectStatus2BackgroundColor",
        color: "projectStatus2InkColor",
    ),
    final-report: (
        borderColor: "projectStatus3BorderColor",
        backgroundColor: "projectStatus3BackgroundColor",
        color: "projectStatus3InkColor",
    ),
);

// Colors for Model statuses

$modelStatus: (
    planned: (
        borderColor: "modelStatus1BorderColor",
        backgroundColor: "modelStatus1BackgroundColor",
        color: "modelStatus1InkColor",
    ),
    in-progress: (
        borderColor: "modelStatus2BorderColor",
        backgroundColor: "modelStatus2BackgroundColor",
        color: "modelStatus2InkColor",
    ),
    in-manager-review: (
        borderColor: "modelStatus3BorderColor",
        backgroundColor: "modelStatus3BackgroundColor",
        color: "modelStatus3InkColor",
    ),
    in-client-review: (
        borderColor: "modelStatus4BorderColor",
        backgroundColor: "modelStatus4BackgroundColor",
        color: "modelStatus4InkColor",
    ),
    client-unapproved: (
        borderColor: "modelStatus5BorderColor",
        backgroundColor: "modelStatus5BackgroundColor",
        color: "modelStatus5InkColor",
    ),
    approved: (
        borderColor: "modelStatus6BorderColor",
        backgroundColor: "modelStatus6BackgroundColor",
        color: "modelStatus6InkColor",
    ),
);

// Colors for Component Model statuses

$componentModelStatus: (
    proposed: (
        borderColor: "componentModelStatus1BorderColor",
        backgroundColor: "componentModelStatus1BackgroundColor",
        color: "componentModelStatus1InkColor",
    ),
    in-progress: (
        borderColor: "componentModelStatus2BorderColor",
        backgroundColor: "componentModelStatus2BackgroundColor",
        color: "componentModelStatus2InkColor",
    ),
    final: (
        borderColor: "componentModelStatus3BorderColor",
        backgroundColor: "componentModelStatus3BackgroundColor",
        color: "componentModelStatus3InkColor",
    ),
);

// Colors for Model Inputs statuses

$modelInputStatus: (
    waiting-on-data: (
        borderColor: "modelInputStatus1BorderColor",
        backgroundColor: "modelInputStatus1BackgroundColor",
        color: "modelInputStatus1InkColor",
    ),
    in-progress: (
        borderColor: "modelInputStatus2BorderColor",
        backgroundColor: "modelInputStatus2BackgroundColor",
        color: "modelInputStatus2InkColor",
    ),
    pending-review: (
        borderColor: "modelInputStatus3BorderColor",
        backgroundColor: "modelInputStatus3BackgroundColor",
        color: "modelInputStatus3InkColor",
    ),
    manager-signed-off: (
        borderColor: "modelInputStatus4BorderColor",
        backgroundColor: "modelInputStatus4BackgroundColor",
        color: "modelInputStatus4InkColor",
    ),
);
