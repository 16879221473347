@import "~assets/sass/_mixins";

.validation-errors-table {
    position: relative;
    margin: 10px 0 5px;

    ul {
        list-style: disc;
        padding-left: 15px;

        li {
            font-size: pxToRem(14);
            line-height: 22px;
            color: $grey-dark;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .icon-wrap-small {
                line-height: 22px;
                margin-left: 10px;
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $fade-z-index + 1;

        // TODO: Replace
        @include themify($themes) {
            background-color: themed("themeWhiteColorSemiTransparent80");
        }

        .loading-input-validations {
            align-self: center;
            margin: auto;
        }
    }

    &__action-row {
        margin: 15px 0 0 0;

        > .icon-with-label:not(:first-child) {
            margin-left: 20px;
        }

        .paging-wrap {
            padding: 0;
            margin-right: 20px;
        }
    }
}
