@import "~assets/sass/_mixins";

/* 
    openModalDialogAvoidedCostsSetup
    openModalDialogAvoidedCostsBulkEdit
*/

.ac-margin-bottom {
    margin-bottom: 20px;
}

.ac-margin-title {
    margin-top: 13px;
}

.ac-margin-right {
    margin-right: 15px;
}

.ac-status-box {
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 20px;
}

.ac-column-checkbox {
    margin: 0 0 20px 0;

    @include calc(width, "100% / 2");
}

.ac-peak-season {
    width: 100px;
}

.ac-measure-label {
    font-size: pxToRem(12);
    line-height: 16px;
}
