.sidenav-header {
    padding: 20px;

    &__title {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);
        font-family: var(--ids-semantic-font-font-family-label);
        font-weight: var(--ids-semantic-font-fixed-label1-bold-font-weight);
        font-size: 1.25rem;
        line-height: 120%;
    }
}
