@import "~assets/sass/_mixins";

.folder-chain {
    margin-bottom: 20px;

    &__items {
        margin-left: 10px;
    }

    &__item-name {
        font-size: pxToRem(14);
        line-height: 22px;
    }

    &__item-separator {
        border-left: 2px solid $grey-light;
        width: 0;
        height: 26px;
        margin: 0 10px;
    }
}
