.label-with-icon {
    display: inline-block;
    align-items: center;
    color: var(--ids-semantic-ink-color-neutral-accent-onlight);
    font-family: var(--ids-semantic-font-font-family-label);
    font-weight: var(--ids-semantic-font-fixed-label1-medium-font-weight);
    font-size: pxToRem(14);
    line-height: 24px;

    &.disabled {
        color: var(--ids-semantic-ink-color-neutral-subtlest);
        pointer-events: none;
    }

    &.label-sm {
        line-height: 24px;
    }

    &.label-md {
        line-height: 28px;
    }

    &.label-lg {
        line-height: 34px;
        font-size: 16px;
        padding: 8px;
    }

    &.regular {
        display: flex;
        align-items: center;
        font-family: var(--ids-semantic-font-font-family-body);
        font-weight: normal;

        svg {
            fill: var(--ids-semantic-ink-color-brand-a-accent);
        }
    }

    &.clickable {
        color: var(--ids-semantic-ink-color-brand-a-accent);

        svg {
            fill: currentColor;
        }
    }

    &__label {
        padding-left: 5px;
    }
}
