@import "~assets/sass/_mixins";

$model-input-list-column-width: (
    // size of the icon width and left padding
    required: 29px,
    // dataset-name: 0,
    upload: 160px,
    review: 60px,
    status: 160px,
    // size of the button width and right padding
    note: 41px
);

$model-input-list-column-padding: 8px;

.model-inputs {
    &__sticky-header {
        background-color: $white;
        position: sticky;
        top: 0;
        z-index: 2;

        .model-progress-bar {
            font-size: pxToRem(14);
            line-height: 22px;
        }
    }

    &__fade {
        background: linear-gradient(to top, rgba(255, 255, 255, 0), white 80%);
        top: 120px;

        @include fade(20px);
    }

    .content-header.sticky {
        background-color: $white;
        top: 80px;
        padding: 10px 0;
    }

    .column {
        @each $column, $size in $model-input-list-column-width {
            &-#{$column} {
                max-width: $size;
            }
        }

        &-required,
        &-dataset-name,
        &-upload,
        &-review,
        &-status {
            padding-left: $model-input-list-column-padding;
        }
    }

    #header {
        &-required {
            max-width: 0;
            padding: 0;
        }
    }

    .equipment-inputs-row {
        min-height: 44px;
        height: fit-content;
        padding: 13px 0;

        &__title {
            max-width: fit-content;
            font-weight: 500;
            font-size: pxToRem(14);
        }

        &__details {
            font-size: pxToRem(14);
            margin: 10px 0 0 0;
        }

        &__errors {
            font-size: pxToRem(14);
            margin: 0 0 0 24px;
        }

        .update-info {
            flex-shrink: 0;
            font-size: pxToRem(14);
        }

        .review-data {
            min-height: 30px;
            min-width: 30px;
        }
    }
}
