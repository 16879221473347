@import "~assets/sass/_mixins";

.text-edit-input-field {
    .input-container {
        width: 180px;
    }

    .check-done,
    .clear-close {
        margin: 0 auto;
    }

    .check-done {
        @include themify($themes) {
            color: themed("primaryBackgroundColor");
        }
    }

    .clear-close {
        @include themify($themes) {
            color: themed("primaryColor");
        }
    }
}
