@import "~assets/sass/_mixins";

.button {
    min-width: 120px;
    font-weight: 700;
    font-size: pxToRem(14);
    padding: 15px;
    border-radius: 22px;

    @include themify($themes) {
        border: 1px solid themed("primaryColor");
        background-color: themed("primaryBackgroundColor");
        color: themed("primaryColor");
    }

    &:not(:disabled):hover {
        cursor: pointer;
    }

    &:not(.button__primary):hover {
        @include box-shadow-common($shadow-button-secondary-hover);
    }

    &__primary:not(:disabled) {
        font-size: pxToRem(12);
        color: $white !important;
        text-transform: uppercase;

        @include themify($themes) {
            background-color: themed("primaryColor");
        }

        &:hover {
            @include box-shadow-common($shadow-button-primary-hover);
        }
    }

    &.focused:not(:disabled) {
        @include themify($themes) {
            border-color: themed("secondaryColor");
        }
    }

    &:disabled {
        border-color: $gray-100;
        background-color: $gray-100;
        color: $grey-medium;
        pointer-events: none;
    }

    &__danger {
        color: $red-medium !important;
    }

    &__square {
        min-width: auto;
        width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 8px;
    }

    &.with-icon {
        padding: 10px 15px;

        &:before {
            font-size: 18px;
            margin-right: 10px;
        }
    }
}
