@import "~assets/sass/_mixins";

$market-profile-data-list-column-width: (
    sector: 140px,
    last-update: 170px,
    uploaded-by: 150px,
);

$market-profile-data-list-column-padding: 8px;

.market-profile-snapshot {
    &__message {
        font-size: pxToRem(14);
        line-height: 22px;
    }

    &__data-container {
        margin-top: 40px;

        .market-profile-data-list-row {
            &.row-with-error {
                height: 84px;
            }

            .row-error-message {
                padding-left: 13px;
            }
        }

        #header,
        .column {
            &-sector,
            &-last-update,
            &-uploaded-by {
                padding-left: $market-profile-data-list-column-padding;
            }
        }

        .column {
            @each $column, $size in $market-profile-data-list-column-width {
                &-#{$column} {
                    max-width: $size;
                }
            }

            &-result {
                @include calc(max-width, "(28px * 2) + 10px");
            }
        }
    }

    &__report-container {
        margin-top: 40px;
    }

    &__report-wrapper {
        height: 860px;
    }
}
