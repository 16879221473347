@import "~assets/sass/_mixins";

.checkbox-input-container {
    &:hover {
        .input-field {
            &:not(:checked) {
                &:before {
                    @include themify($themes) {
                        color: themed("selectionColor");
                    }
                }
            }
        }
    }

    .input-field {
        &:before {
            content: $icon-checkbox-unchecked-empty;
            width: 24px;
            height: 24px;
            font-size: 18px;
            line-height: 24px;
            color: $grey-dark;
        }

        &:checked {
            &:before {
                content: $icon-checkbox-checked-filled;

                @include themify($themes) {
                    color: themed("selectionColor");
                }
            }
        }

        &:disabled,
        &.read-only {
            &:before {
                color: $grey-white-medium !important;
            }
        }

        &.error {
            &:before {
                color: $red-medium;
            }
        }

        &:not(:disabled):not(.read-only) {
            cursor: pointer;
        }
    }

    .label {
        &:not(.disabled):not(.read-only) {
            cursor: pointer;
        }
    }
}
