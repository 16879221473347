@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";

.input-container {
    &__wrapper {
        position: relative;

        input[type="text"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input:not([type]).input-field {
            border: 1px solid $grey-medium;
            width: 100%;
            height: 40px;
            font-size: pxToRem(14);
            padding: 0 8px;
            border-radius: 8px;

            @include text-overflow;

            @include placeholder {
                font-style: italic;
                color: $grey-white-dark;
            }

            &::-ms-clear,
            &::-ms-reveal {
                display: none;
            }

            &:hover {
                @include themify($themes) {
                    border-color: themed("selectionColor");
                }
            }

            &:not(.error):not(.warning) {
                &:focus,
                &.focus {
                    @include themify($themes) {
                        border-color: themed("selectionColor");
                    }
                }
            }

            &:disabled {
                border-color: $grey-white-medium;
                background-color: $gray-100;
                color: $grey-white-dark;
            }

            &.read-only {
                border-color: $grey-white-medium;
                background-color: $gray-100;
                pointer-events: none;
            }

            &.ghost {
                border: none;
                font-weight: 700;
                pointer-events: none;
            }

            &.input-table-filter {
                border: none;
                border-radius: 0;
            }

            &.error {
                border-color: $red-medium;
                color: $red-medium;
            }

            &.warning {
                border-color: $yellow-dark;
                background-color: $yellow-white;
            }
        }

        input[type="number"].input-field {
            border: 1px solid $grey-medium;
            width: 90px;
            font-style: italic;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                appearance: none;
            }

            &:disabled,
            &.read-only,
            &.ghost,
            &:focus,
            &.focus,
            &.error,
            &.warning {
                font-style: normal;
            }
        }

        .icon-wrap {
            position: absolute;
            right: 8px;
            bottom: 8px;
        }

        input[type="checkbox"],
        input[type="radio"] {
            appearance: none;
        }

        // Below Input element
        ~ .status-msg {
            margin-top: 8px;

            &:not(.error) {
                color: $grey-dark;
            }
        }
    }
}
