@import "~assets/sass/_mixins";

.window-tabs {
    background-color: var(--ids-semantic-background-color-brand-a-subtlest-default);
    height: 56px;
    z-index: $fade-z-index + 1;

    @include box-shadow-common($shadow-windows-outer);

    &__container {
        padding: 0 15px;
    }

    &__vision-logo {
        background-image: url("https://files.insight.visiondsm.com/public/Vision-Icon-Gradient.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        width: 32px;
        height: 32px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    // Note: This container goes through whole
    // page's height
    &__tabs {
        position: absolute;
        bottom: 0;
        left: 62px;
        pointer-events: none;
        z-index: $fade-z-index + 2;

        @include calc(width, "100% - 77px"); // left alignment (62px) + right padding (15px)
    }
}
