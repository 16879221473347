@import "~assets/sass/_mixins";

.runs-tab {
    &__vertical-line {
        border-left: 1px solid var(--ids-semantic-border-color-neutral-subtlest);
        width: 0;
        height: 100%;

        &.hide-line {
            border: none;
        }
    }

    &__circle {
        border: 1px solid var(--ids-semantic-border-color-brand-a-accent);
        border-radius: 50%;
        width: 7px;
        height: 7px;
        flex-shrink: 0;
    }

    &__run-details-container {
        border-bottom: 1px solid var(--ids-semantic-border-color-neutral-subtlest);
        padding: 15px 5px 10px 15px;
    }

    &__scenario-name {
        @include semanticFontFixedLabel2Bold;

        font-size: pxToRem(14); // TODO: Remove when base font size is fixed
    }

    &__results-log,
    &__run-time {
        @include semanticFontFixedBody3Regular;

        font-size: pxToRem(12); // TODO: Remove when base font size is fixed
    }

    &__run-actions-container {
        margin-left: 5px;
    }

    &__storage-status-container {
        margin-right: 10px;
    }
}
