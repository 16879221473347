@import "~assets/sass/_mixins";

.project-stakeholder-dashboard {
    &__body-container {
        padding: 20px 30px;

        > div:not(:first-child) {
            margin-left: 20px;
        }

        .project-level-reports-panel-wrapper {
            @include calc(width, "100% / 3 - 50px");
        }

        .standalone-reports-panel-wrapper {
            @include calc(width, "100% / 3 + 100px");
        }

        .project-stakeholder-dashboard-panel {
            min-height: $project-stakeholder-dashboard-panel-height;
        }
    }
}
