@import "~assets/sass/_mixins";

.form-wrap-main-container {
    padding: 35px 65px 0 65px;

    @include themify($themes) {
        background-color: themed("appBackgroundColor");
    }

    @include laptop {
        padding-top: 5px;
    }

    &__wrapper-container {
        flex: 100%;
        align-self: center;
        width: 440px;

        .logo {
            background-image: url("https://files.insight.visiondsm.com/public/Vision-Horizontal-Signature-Blue.svg");

            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            width: 325px;
            height: 85px;
            margin: 0 auto 30px;
        }
    }

    &__copyright-container {
        height: 35px;
    }
}
