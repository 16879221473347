@import "~assets/sass/_mixins";

.nothing-found-block {
    padding: 5px 30px;

    &__title {
        font-weight: 500;
        font-size: pxToRem(18);
        line-height: 21px;

        + .nothing-found-block__message {
            font-size: pxToRem(14);
            line-height: 22px;
            margin-top: 15px;
        }
    }

    .not-found-icon {
        background-color: var(--ids-semantic-background-color-brand-a-subtlest-active);
        color: var(--ids-semantic-ink-color-brand-a-accent);
        height: 50px;
        width: 50px;
        border-radius: 25px;
        padding: 10px;
        margin-bottom: 20px;

        svg {
            fill: currentColor;
        }
    }
}
