@import "~assets/sass/_mixins";

.avatar {
    font-family: var(--ids-semantic-font-font-family-label);
    font-size: pxToRem(12);
    text-align: center;
    border-radius: 100%;
}

.user-avatar {
    font-family: var(--ids-semantic-font-font-family-label);
    font-size: pxToRem(14);
    text-align: center;
    border-radius: 100%;
}
