@import "~assets/sass/_mixins";

.dropdown-container {
    &__wrapper {
        position: relative;

        .dropdown-field {
            border: 1px solid $grey-medium;
            appearance: none;
            min-height: 40px;
            width: 100%;
            height: 100%;
            font-size: pxToRem(14);
            line-height: 22px;
            padding: 0 24px 0 8px; // 24px is the width of shevron icon
            border-radius: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
                cursor: pointer;

                @include themify($themes) {
                    border-color: themed("selectionColor");
                }
            }

            &.placeholder:not(.ghost) {
                font-style: italic;
                color: $grey-white-dark !important;
            }

            &:focus:not(.input-table-filter):not(.error):not(.warning),
            &.focus:not(.input-table-filter):not(.error):not(.warning) {
                @include themify($themes) {
                    border-color: themed("selectionColor");
                }
            }

            &:disabled {
                border-color: $grey-white-medium;
                background-color: $gray-100;
                color: $grey-white-dark;
                pointer-events: none;

                // Icon colors
                + .shevron-in-circle-up-filled,
                + .shevron-small-down-expand-more,
                + .dropdown-container__wrapper__value-box-container + .shevron-small-down-expand-more,
                + .dropdown-container__wrapper__value-box-container + .shevron-in-circle-up-filled {
                    color: $grey-white-dark;
                }
            }

            &.read-only {
                border-color: $grey-white-medium;
                background-color: $gray-100;
                pointer-events: none;

                // Icon colors
                + .shevron-in-circle-up-filled,
                + .shevron-small-down-expand-more,
                + .dropdown-container__wrapper__value-box-container + .shevron-small-down-expand-more,
                + .dropdown-container__wrapper__value-box-container + .shevron-in-circle-up-filled {
                    color: $grey-dark;
                }
            }

            &.ghost {
                border: none;
                font-weight: 700;
                pointer-events: none;

                + .dropdown-container__wrapper__value-box-container {
                    .dropdown-container__wrapper__value-box {
                        gap: 10px 5px;

                        .ghost-value {
                            font-weight: 700;
                            z-index: 1;
                        }
                    }
                }
            }

            &.input-table-filter {
                border: none;
                border-radius: 0;
            }

            &.error {
                border-color: $red-medium;
                color: $red-medium;
            }

            &.warning {
                border-color: $yellow-dark;
                background-color: $yellow-white;
            }

            &[multiple] {
                position: absolute;
                color: transparent;
            }
        }

        &__value-box-container {
            min-height: 40px;
            padding: 8px;
            overflow: hidden;

            // 24px - size of the shevron
            // 8px - right alignment
            @include calc(width, "100% - (24px + 8px)");

            &.single-line {
                height: 40px;

                .dropdown-container__wrapper__value-box {
                    margin-right: 10px;
                }
            }
        }

        &__value-box {
            gap: 10px;

            .button-tag-wrapper {
                &.hide-button-tag {
                    visibility: hidden;
                    z-index: -1;

                    .button-tag {
                        pointer-events: none;
                    }
                }

                .button-tag {
                    cursor: pointer;
                    user-select: none;
                }
            }

            .placeholder-value {
                padding: 2px 0;
                z-index: 1;
                pointer-events: none;

                &.placeholder-ghost-value {
                    border: none;
                    font-weight: 700;
                }

                &:not(.placeholder-ghost-value) {
                    font-style: italic;
                    color: $grey-white-dark;
                }
            }

            .counter-value {
                pointer-events: none;
                z-index: 1;
            }

            .ghost-value {
                font-weight: 500;
                z-index: 1;
            }
        }

        .shevron-in-circle-up-filled,
        .shevron-small-down-expand-more {
            position: absolute;
            right: 0;
            bottom: 50%;
            pointer-events: none;
            transform: translateY(50%);

            @include themify($themes) {
                color: themed("primaryColor");
            }
        }
    }

    // Below Dropdown element
    ~ .status-msg {
        margin-top: 8px;
    }
}
