@import "~assets/sass/_mixins";

.dropdown-component {
    &__filter {
        border-bottom: 1px solid $grey-light;
        border-radius: 8px 8px 0 0;

        + .dropdown-component-items {
            // Display maximum of 5 items at once
            // if filter is present
            max-height: 190px;
            border-radius: 0 0 8px 8px;
        }
    }
}

.dropdown-component-items {
    // Display maximum of 6 items at once
    max-height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 8px;
}
