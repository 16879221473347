@import "~assets/sass/_mixins";

.welcome-board {
    border: none;
    border-radius: 8px;
    background-color: var(--ids-semantic-background-color-neutral-accent-default-ondark);
    box-shadow: none;
    position: relative;
    height: 45px;
    font-size: pxToRem(14);
    line-height: 21px;
    color: var(--ids-semantic-background-color-brand-a-subtlest-default);
    margin-bottom: 0;
    overflow: hidden;

    &__welcome-message {
        font-weight: var(--ids-semantic-font-fixed-body2-bold-font-weight);
    }

    &__helper {
        margin-left: 10px;
    }

    &__vision-logo {
        background-image: url("https://files.insight.visiondsm.com/public/Vision-Icon-White.svg");
        background-repeat: no-repeat;
        background-size: cover;

        position: absolute;
        width: 90px;
        height: 90px;

        &.vision-logo-bottom {
            top: 7px;

            @include calc(right, "15px + 90px");
        }

        &.vision-logo-top {
            right: 15px;
            bottom: 7px;
        }
    }
}
