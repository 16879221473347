@import "~assets/sass/_mixins";

$project-list-column-width: (
    // size of the icon width and left padding
    // Project Default
    bookmarked: 45px,
    project-name: 250px,
    started: 150px,
    target-date: 150px,
    project-state: 150px,
    actions: 45px,
    // Project Stakeholder
    finished: 150px
);

$project-list-column-padding: 8px;

.project-list {
    .column {
        @each $column, $size in $project-list-column-width {
            &-#{$column} {
                max-width: $size;
            }
        }

        &-project-name,
        &-project-description,
        &-started,
        &-target-date,
        &-project-state,
        &-finished {
            padding-left: $project-list-column-padding;
        }

        &-filter {
            padding: 0;

            &.project-default {
                &.column-project-name {
                    max-width: map-get($project-list-column-width, project-name) + map-get($project-list-column-width, bookmarked);
                }

                &.column-project-state {
                    max-width: map-get($project-list-column-width, project-state) + map-get($project-list-column-width, actions) +
                        $scrollbar-size;
                }
            }
        }
    }

    #header {
        &-bookmarked {
            max-width: 0;
            padding: 0;

            + #header-project-name {
                max-width: map-get($project-list-column-width, project-name) + map-get($project-list-column-width, bookmarked);
            }
        }

        &-project-state {
            max-width: map-get($project-list-column-width, project-state) + map-get($project-list-column-width, actions);
        }

        &-actions {
            max-width: 0;
            padding: 0;
        }
    }
}
