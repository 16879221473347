@import "~assets/sass/_mixins";

.component-with-dropdown {
    &__container {
        position: relative;

        &__wrapper {
            display: inline-flex;

            @include themify($themes) {
                color: themed("primaryColor");
            }
        }

        .dropdown-component-items {
            &__item-icon {
                margin-right: 5px;
            }
        }
    }
}
