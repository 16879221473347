@import "~assets/sass/_mixins";

.note-view {
    &__tab-panel-header-container {
        padding-bottom: 20px;
    }

    &__panel-header {
        font-family: var(--ids-semantic-font-font-family-label);
        font-size: pxToRem(18);
        font-weight: 500;
        line-height: 21px;
        margin-right: 5px;
    }

    &__top-container {
        padding-bottom: 10px;
    }

    &__author {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);
        font-family: var(--ids-semantic-font-font-family-body);
        font-size: pxToRem(12);
        font-weight: 700;
        line-height: 150%;
        margin-right: 5px;
    }

    &__date {
        color: var(--ids-semantic-ink-color-neutral-subtle-onlight);
        font-family: var(--ids-semantic-font-font-family-body);
        font-size: pxToRem(12);
        font-weight: 400;
        line-height: 150%;
    }

    &__note {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);
        font-size: pxToRem(14);
        margin-bottom: 10px;
    }
}
