@import "~assets/sass/_mixins";

.icon-with-label {
    display: inline-block;
    font-weight: 500;
    font-size: pxToRem(14);
    line-height: 24px;
    padding-bottom: 1px;

    // First value is width of the icon
    // Second value is space between the icon
    // and label
    @include calc(padding-left, "24px + 5px");

    &.with-icon {
        &:before,
        &:after {
            position: absolute;
            top: 1px;
            width: 24px;
            height: 24px;
            font-size: 18px;
            line-height: 24px;
            color: $grey-dark;
            text-align: center;
            border-radius: 50%;
        }

        &:before {
            left: 0;
        }
    }

    &.disabled {
        color: $grey-white-dark;
        pointer-events: none;
    }

    &.icon-with-label-small {
        font-size: pxToRem(12);
        line-height: 22px;

        @include calc(padding-left, "20px + 5px");

        &.with-icon {
            &:before,
            &:after {
                width: 20px;
                height: 20px;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &.icon-with-label-medium {
        font-size: pxToRem(16);
        line-height: 34px;

        @include calc(padding-left, "30px + 5px");

        &.with-icon {
            &:before,
            &:after {
                width: 30px;
                height: 30px;
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    &.icon-after-label {
        padding-left: 0;

        @include calc(padding-right, "24px + 5px");

        &.icon-with-label-small {
            @include calc(padding-right, "16px + 5px");
        }

        &.icon-with-label-medium {
            @include calc(padding-right, "30px + 5px");
        }

        &.with-icon {
            &:after {
                right: 0;
            }
        }
    }

    &.clickable {
        @include themify($themes) {
            color: themed("primaryColor");
        }

        &.with-icon {
            &:before,
            &:after {
                @include themify($themes) {
                    color: themed("primaryColor");
                }
            }
        }
    }

    &.inverse.clickable {
        &.with-icon {
            &:before,
            &:after {
                @include themify($themes) {
                    background-color: themed("primaryColor");
                    color: themed("primaryBackgroundColor");
                }
            }
        }
    }
}
