@import "~assets/sass/_mixins";

.switcher {
    .switcher-container {
        background-color: $grey-white-dark;
        position: relative;
        width: 38px;
        height: 20px;
        padding: 0 4px;
        border-radius: 15px;

        .switcher-thumb {
            background-color: $white;
            position: absolute;
            left: 4px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        &.active {
            @include themify($themes) {
                background-color: themed("selectionColor");
            }

            .switcher-thumb {
                right: 4px;
                left: initial;
            }
        }

        &.disabled,
        &.read-only {
            background-color: $grey-white-medium !important;
        }

        &:not(.disabled):not(.read-only) {
            cursor: pointer;
        }
    }

    .switcher-label {
        font-weight: 500;
        font-size: pxToRem(12);

        &:not(.disabled):not(.read-only) {
            cursor: pointer;
        }
    }
}
