@import "~assets/sass/_mixins";

.bookmarked-project-statuses {
    &__total-projects {
        font-weight: 500;
        font-size: 52px;
        line-height: 51px;
    }

    &__chart-container {
        margin: 0 40px;

        .chart-line {
            height: 8px;
            margin-bottom: 5px;

            &:last-child {
                margin: 0;
            }
        }
    }

    &__legends-container {
        .legend-label-container {
            margin-bottom: 10px;

            &:last-child {
                margin: 0;
            }

            .legend-label {
                font-size: pxToRem(12);
                line-height: 16px;
            }

            .ellipsis {
                width: 8px;
                height: 8px;
                margin-left: 5px;
                border-radius: 50%;
            }
        }
    }

    .chart-line,
    .ellipsis {
        @each $status, $color in $projectStatus {
            &.#{$status} {
                @include themify($themes) {
                    background-color: themed(map-get($color, color));
                }
            }
        }
    }
}
