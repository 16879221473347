.icon-preparing {
    position: relative;
    width: 21px;
    height: 21px;
    display: inline-block;

    .item {
        background-color: rgba(0, 0, 0, 0.54);
        border-radius: 50%;
        position: absolute;
        width: 3px;
        height: 3px;
        animation: preparing 1.2s linear infinite;

        &:nth-child(1) {
            top: 8px;
            left: 16px;
            animation-delay: 0s;
        }

        &:nth-child(2) {
            top: 4px;
            left: 15px;
            animation-delay: -0.1s;
        }

        &:nth-child(3) {
            top: 1px;
            left: 12px;
            animation-delay: -0.2s;
        }

        &:nth-child(4) {
            top: 0;
            left: 8px;
            animation-delay: -0.3s;
        }

        &:nth-child(5) {
            top: 1px;
            left: 4px;
            animation-delay: -0.4s;
        }

        &:nth-child(6) {
            top: 4px;
            left: 1px;
            animation-delay: -0.5s;
        }

        &:nth-child(7) {
            top: 8px;
            left: 0;
            animation-delay: -0.6s;
        }

        &:nth-child(8) {
            top: 12px;
            left: 1px;
            animation-delay: -0.7s;
        }

        &:nth-child(9) {
            top: 15px;
            left: 4px;
            animation-delay: -0.8s;
        }

        &:nth-child(10) {
            top: 16px;
            left: 8px;
            animation-delay: -0.9s;
        }

        &:nth-child(11) {
            top: 15px;
            left: 12px;
            animation-delay: -1s;
        }

        &:nth-child(12) {
            top: 12px;
            left: 15px;
            animation-delay: -1.1s;
        }
    }
}

@keyframes preparing {
    0%,
    80%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}
