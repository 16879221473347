// @todo: remove when 1rem is 16px
@import "~assets/sass/_mixins";

.aeg-tag {
    border-radius: 100px;

    &.ids-tag .label {
        font-weight: 500;
        font-size: 0.9rem;
        line-height: 2rem;
        text-transform: capitalize;
    }

    @each $status, $color in $projectStatus {
        &.#{$status} {
            @include themify($themes) {
                border: 1px solid themed(map-get($color, borderColor)) !important;
                background-color: themed(map-get($color, backgroundColor)) !important;
                color: themed(map-get($color, color));
            }
        }
    }

    @each $status, $color in $modelStatus {
        &.#{$status} {
            @include themify($themes) {
                border: 1px solid themed(map-get($color, borderColor)) !important;
                background-color: themed(map-get($color, backgroundColor)) !important;
                color: themed(map-get($color, color));
            }
        }
    }

    @each $status, $color in $componentModelStatus {
        &.#{$status} {
            @include themify($themes) {
                border: 1px solid themed(map-get($color, borderColor)) !important;
                background-color: themed(map-get($color, backgroundColor)) !important;
                color: themed(map-get($color, color));
            }
        }
    }

    @each $status, $color in $modelInputStatus {
        &.#{$status} {
            @include themify($themes) {
                border: 1px solid themed(map-get($color, borderColor)) !important;
                background-color: themed(map-get($color, backgroundColor)) !important;
                color: themed(map-get($color, color));
            }
        }
    }
}
