@import "~assets/sass/_mixins";

.result-container {
    border: 1px solid $grey-light;
    border-radius: 18px;
    width: calc(100% / 3);
    padding: 15px;

    &:not(:first-child) {
        margin-left: 20px;
    }

    .result-title {
        margin-bottom: 5px;
    }

    .result-date {
        padding-left: 5px;
    }
}
