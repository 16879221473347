@import "~assets/sass/_mixins";

.bookmarked-projects-container {
    &__no-bookmarks {
        font-weight: 500;
        font-size: pxToRem(18);
        line-height: 24px;
        margin: 20px 0;
    }

    &__header {
        font-weight: 500;
        font-size: pxToRem(21);
        line-height: 24px;
        padding: 25px 0;
    }

    .bookmarked-project-statuses-panel {
        max-height: 180px;
    }

    .bookmarked-projects-panel {
        min-height: 135px;
        max-height: 305px;
        margin-top: 20px;
    }
}
