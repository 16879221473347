@import "~assets/sass/_mixins";

.calculation-workflow-sidebar {
    &__step-number {
        background-color: var(--ids-semantic-background-color-neutral-subtlest-default);
    }

    &__module-name {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);

        margin-left: 10px;

        @include semanticFontFixedHeading4Bold;
    }

    &__more {
        font-weight: 400;
    }

    &__tabs-container {
        margin-top: 15px;
    }
}
