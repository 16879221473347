@import "~assets/sass/_variables";

.standalone-report-dashboard {
    &__body-container {
        padding: 0 30px;
        margin: 20px 0;

        > div:not(:first-child) {
            margin-top: 20px;
        }

        .embedded-panel {
            height: $embedded-panel-height;
        }

        .report-log-panel {
            min-height: $report-log-panel-height;
        }
    }
}
