@import "~assets/sass/_mixins";

.model-study-case {
    &__calculation-section {
        margin: 0 0 20px 0;
    }

    &__case-error {
        background-color: $red-white;
        padding: 13px 10px 10px 10px;
        border-radius: 10px;
    }
}
