@import "~assets/sass/_mixins";

.info-panel {
    border-right: 2px solid $grey-medium;
    border-left: 2px solid $grey-medium;
    background-color: $grey-white;
    padding: 20px;
    margin: 20px 0;

    @include box-shadow-common((inset 0px -1px 0px $grey-light, inset 0px 1px 0px $grey-light));

    &.error {
        border-color: $red-medium;
    }

    &.success {
        border-color: $green;
    }

    &.warning {
        border-color: $yellow-dark;
    }
}
