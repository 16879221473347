.checkbox-card {
    &__checked {
        .card {
            border-color: var(--ids-semantic-border-color-brand-a-subtle) !important;
        }
    }

    &__read-only-icon {
        fill: var(--ids-semantic-ink-color-neutral-subtlest);

        margin-left: 5px;

        &.checked {
            fill: var(--ids-semantic-ink-color-brand-a-subtlest);
        }
    }
}
