@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.view-placeholder {
    font-weight: 700;
    font-size: pxToRem(32);
    color: rgba(0, 0, 0, 0.11);

    &__clickable-text {
        display: flex;
        justify-content: center;
        margin-top: 7px;
    }

    .wait-icon {
        align-self: initial;
    }

    &:not(.placeholder-split-screen) {
        justify-content: center;
        height: 100%;
        min-height: 100%;
    }

    &.placeholder-split-screen {
        background-color: $white;
        font-weight: 500;
        color: #868c8f;
        padding: 24px 30px;

        .split-view-inner-block {
            border: 4px dashed $grey-light;
            width: 100%;
            height: 100%;
            padding: 116px 97px;
        }
    }
}
