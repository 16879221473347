@import "~assets/sass/_mixins";

$nesting-space: 20px;

.module-card {
    min-height: 67px;

    &.module-card-secondary {
        margin-left: $nesting-space;
    }

    &.module-card-tertiary {
        @include calc(margin-left, $nesting-space * 2);
    }

    &__drag-and-drop-icon-container {
        margin-right: 20px;
    }

    &__module-name {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);

        @include semanticFontFixedHeading5Bold;
    }

    &__module-name {
        margin: 0 10px;
    }

    &__calculation-status {
        border-radius: 15px;
    }

    &__bottom-container {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);

        margin-top: 5px;

        @include semanticFontFixedBody3Regular;

        font-size: pxToRem(12); // TODO: Remove when base font size is fixed
    }

    &__progress-bar {
        width: 380px;
    }

    &__progress-label {
        font-weight: 400;
        font-size: pxToRem(14);
        line-height: 22px;

        margin-left: 10px;
    }

    &__link {
        color: var(--ids-semantic-ink-color-brand-a-accent);

        text-decoration: underline;
    }

    &__date {
        margin-left: 5px;
    }

    &__right-container {
        justify-content: flex-end;
    }

    &__module-state-container {
        min-width: 180px;
    }

    &__calculate-button-container {
        width: 140px;
        margin-left: 20px;
    }
}
