@import "~assets/sass/_mixins";

.import-data-form {
    &:not(.import-data-form-modal) {
        border: 1px solid $grey-light;
        border-radius: 18px;
        background-color: $white;
        padding: 25px;
    }

    &__title {
        font-weight: 500;
        font-size: pxToRem(18);
        line-height: 21px;
        margin: 0 0 30px 0;
    }

    &__section-separator {
        font-size: pxToRem(12);
        margin-bottom: 15px;
    }

    &__important-note {
        margin-bottom: 20px;
    }

    &__actions-separator {
        margin: 0 20px;
    }
}
