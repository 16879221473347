@import "~assets/sass/_mixins";

.qr-code-form {
    &__error-message {
        margin-bottom: 25px;
    }

    &__header {
        font-weight: 600;
        font-size: pxToRem(16);
        line-height: 22px;
    }

    &__two-fa-setup-steps {
        margin: 20px 0;

        ul {
            background-color: $white;
            counter-reset: step;
            width: 59%;
            padding: 15px;

            li {
                position: relative;
                font-size: pxToRem(14);
                line-height: 22px;
                padding-left: 25px;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &:before {
                    counter-increment: step;
                    content: counter(step);
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 1;

                    @include themify($themes) {
                        color: themed("primaryColor");
                    }
                }

                .icon-wrap {
                    margin-left: 5px;
                }
            }
        }
    }

    &__qr-code-wrap {
        width: 41%;
        margin-left: 20px;

        .qr-code {
            width: 100%;
        }
    }

    &__inputs {
        margin-bottom: 25px;
    }

    &__redirect {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;

        @include themify($themes) {
            color: themed("primaryColor");
        }
    }
}
