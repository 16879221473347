@import "~assets/sass/_mixins";

.dropdown-list {
    background-color: $white;
    min-width: 250px;
    user-select: none;
    z-index: $fade-z-index + 1;
    border-radius: 8px;

    @include themify($themes) {
        border: 1px solid themed("selectionColor");
    }

    &.top-placement {
        // Overwrite React Popper style
        bottom: 8px !important;
    }

    &.bottom-placement {
        top: 8px !important;
    }

    &[data-popper-reference-hidden~="true"] {
        visibility: hidden;
        pointer-events: none;
    }
}
