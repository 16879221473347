@import "~assets/sass/_mixins";

.tooltip {
    display: inline-block;

    &__icon {
        &:hover {
            + .tooltip__container {
                visibility: visible;
                z-index: 3;
                pointer-events: auto;

                .tooltip-arrow:before {
                    visibility: visible;
                }
            }
        }
    }

    &__container {
        background-color: #24242a;
        max-width: 200px;
        font-size: pxToRem(14);
        line-height: 22px;
        color: $white;
        padding: 12px 8px;
        visibility: hidden;
        z-index: -1;
        pointer-events: none;
        border-radius: 8px;

        .tooltip-arrow,
        .tooltip-arrow:before {
            background: inherit;
            position: absolute;
            width: 8px;
            height: 8px;
            visibility: hidden;
            z-index: -1;
        }

        .tooltip-arrow:before {
            content: "";

            @include rotate(45deg);
        }

        &[data-popper-placement^="top"] {
            > .tooltip-arrow {
                bottom: -4px;
            }
        }

        &[data-popper-placement^="right"] {
            > .tooltip-arrow {
                left: -4px;
            }
        }

        &[data-popper-placement^="bottom"] {
            > .tooltip-arrow {
                top: -4px;
            }
        }

        &[data-popper-placement^="left"] {
            > .tooltip-arrow {
                right: -4px;
            }
        }
    }
}
