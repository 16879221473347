.aria-link {
    cursor: pointer;

    &.is-hovered {
    }

    &.is-pressed {
    }

    &.is-focused {
    }

    &.is-focus-visible {
        border: 2px solid var(--ids-semantic-focus-ring-color-brand-a);
    }
}
