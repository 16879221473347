@import "~assets/sass/_mixins";

.button-tag {
    border: 1px solid $grey-medium;
    position: relative;
    font-size: pxToRem(12);
    line-height: 22px;
    padding: 0 26px 0 10px;
    white-space: nowrap;
    border-radius: 22px;

    &.disabled {
        border-color: $grey-white-medium;
        background-color: $gray-100;
        color: $grey-white-dark;
        pointer-events: none;
    }

    &.read-only {
        border-color: $grey-white-medium;
        background-color: $gray-100;
        pointer-events: none;
    }

    &.unremovable {
        padding: 0 10px;
    }

    .icon-wrap {
        position: absolute;
        top: 0;
        right: 0;
    }
}
