@import "~assets/sass/_mixins";

.project-stakeholder-dashboard-header {
    background-color: $white;
    padding: 50px 30px 15px 30px;

    @include box-shadow-common($shadow-emboss);

    &__top-container {
        margin-bottom: 10px;
    }

    &__project-icon-wrapper {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: var(--ids-semantic-ink-color-neutral-subtle-ondark);
    }

    &__project-icon {
        fill: var(--ids-semantic-ink-color-neutral-accent-onlight);
    }

    &__project-name {
        font-size: pxToRem(18);
    }

    &__project {
        color: var(--ids-semantic-ink-color-neutral-subtle-onlight);
        font-family: var(--ids-semantic-font-font-family-label);
        font-size: pxToRem(17);
    }

    &__title {
        font-family: var(--ids-semantic-font-font-family-label);
        font-weight: 500;
        font-size: pxToRem(21);
        line-height: 24px;
    }

    &__middle-container {
        margin-bottom: 10px;
    }

    &__project-details-container {
        margin-right: 20px;
    }

    &__project-details {
        margin-bottom: 10px;
    }

    &__project-actions-container {
        display: flex;
        align-items: center;

        @include laptop {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
