@import "~assets/sass/_mixins";

.icon-custom {
    width: 24px;
    height: 24px;
    border-radius: 50%;

    svg {
        fill: $grey-dark;
        width: 18px;
        height: 18px;
    }

    &.icon-small {
        width: 16px;
        height: 16px;

        svg {
            width: 10px;
            height: 10px;
        }
    }

    &.icon-medium {
        width: 30px;
        height: 30px;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    &.icon-big {
        width: 72px;
        height: 72px;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &.clickable {
        svg {
            @include themify($themes) {
                fill: themed("primaryColor");
            }
        }
    }
}
