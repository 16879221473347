@import "~assets/sass/_mixins";

.client-list {
    .list-filter {
        padding-right: 15px;
        padding-left: 15px;

        .input-field {
            border-radius: 22px;
        }
    }

    .list-item-row {
        cursor: pointer;

        &.selected-row {
            @include themify($themes) {
                border-left: 4px solid themed("primaryColor");
                background-color: themed("primaryBackgroundColor");
            }

            .arrow-in-circle-right-empty {
                display: block !important;
            }
        }

        .arrow-in-circle-right-empty {
            display: none !important;
        }

        &:hover {
            .arrow-in-circle-right-empty {
                display: block !important;
            }
        }
    }

    .client-name {
        font-weight: 500;

        @include themify($themes) {
            color: themed("primaryColor");
        }
    }
}
