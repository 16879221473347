@import "~assets/sass/_mixins";

.vertical-fade {
    position: absolute;
    right: 0;
    left: 0;
    min-height: $vertical-fade-min-height;
    z-index: $fade-z-index;

    @include calc(width, 100% - #{$scrollbar-size});

    &.top-side-fade {
        background: linear-gradient(to top, rgba(255, 255, 255, 0), white 40%);
    }

    &.bottom-side-fade {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 40%);
        bottom: 0;
    }

    &.show-fade {
        opacity: 1;
    }

    &.hide-fade {
        opacity: 0;
        z-index: -1;
    }
}
