.powerbi-icon {
    border-radius: 1rem;
    background-color: var(--ids-semantic-background-color-brand-a-subtlest-default);
}

.powerbi-icon svg {
    margin: 3px;
}

.powerbi-icon.inverse {
    margin: 0;
    background-color: var(--ids-semantic-ink-color-brand-a-accent);
}

.powerbi-icon.inverse svg {
    fill: white;
}
