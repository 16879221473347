@import "~assets/sass/_functions";
@import "~assets/sass/_mixins";
@import "~assets/sass/_variables";

.float-modal-portal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ReactModal__Overlay {
    &.float-modal-overlay {
        @include themify($themes) {
            background-color: themed("themeWhiteColorSemiTransparent80");
        }

        position: inherit;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: start;

        .modal.float-modal-modal,
        .float-modal-modal {
            position: sticky;
            top: 50%;
            left: 0;
            right: 0;
            align-self: unset;
            margin: 0 auto;
            transform: translate(0, -50%);
            border-radius: 8px;

            @include box-shadow-common($shadow-outer-hover);
        }
    }
}

.float-modal-root {
    position: relative;
}
