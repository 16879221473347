@import "~assets/sass/_mixins";

.baseline-module {
    width: 445px;
    padding-top: 50px;

    &__title {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);

        margin-bottom: 15px;

        @include semanticFontFixedHeading5Bold;
    }

    &__actions-container {
        margin-top: 40px;
    }
}
