@import "~assets/sass/_mixins";

.tab-details {
    min-height: 44px;

    &__content {
        padding: 5px 10px;

        @include calc(width, "100% - 24px");
    }

    &__content-icon {
        fill: var(--ids-semantic-ink-color-brand-a-accent);
    }

    &__content-title {
        font-family: var(--ids-semantic-font-font-family-label);
        color: var(--ids-semantic-ink-color-brand-a-accent);
        margin-left: 5px;
        overflow: hidden;
    }

    &__content-main-title {
        display: none;
        font-weight: 500;
        font-size: pxToRem(14);
        margin-bottom: 5px;
    }

    &__content-sub-title {
        max-height: 30px;
        font-weight: 500;
        font-size: pxToRem(12);
    }

    &__close-box {
        background-color: rgba(0, 0, 0, 0.07);
        display: none;

        @include box-shadow(inset 1px, 0, 4px, 0 rgba(0, 0, 0, 0.2));

        .clear-close {
            color: var(--ids-semantic-ink-color-neutral-accent-ondark) !important;
        }
    }

    .icon-custom {
        svg {
            fill: var(--ids-semantic-ink-color-brand-a-accent);
        }
    }

    &.active {
        .tab-details__content {
            &-icon {
                fill: var(--ids-semantic-ink-color-neutral-accent-ondark);
            }

            &-title {
                color: var(--ids-semantic-ink-color-neutral-accent-ondark);
            }
        }

        .icon-custom {
            svg {
                fill: var(--ids-semantic-ink-color-neutral-accent-ondark);
            }
        }
    }

    &:hover {
        .icon-custom {
            svg {
                fill: var(--ids-semantic-ink-color-neutral-accent-ondark);
            }
        }
    }

    &.split-view {
        width: 50%;

        .tab-details__content-title {
            margin-left: 10px;
        }
    }

    &.tab-left {
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        padding-right: 10px;
    }

    &.tab-right {
        border-left: 1px solid rgba(0, 0, 0, 0.25);
        padding-left: 10px;
    }
}
