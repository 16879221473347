@import "~assets/sass/_mixins";

.upload-file-container {
    .upload-file {
        min-height: 40px;
        padding: 0 8px;
        cursor: pointer;
        border-radius: 8px;

        @include themify($themes) {
            border: 1px dashed themed("primaryColor");
            background-color: themed("primaryBackgroundColor");
        }

        &.disabled {
            border-color: $grey-white-medium;
            background-color: $gray-100;
            cursor: default;

            span,
            .upload:before {
                color: $grey-white-dark !important;
            }
        }

        &.error {
            border-color: $red-medium;
        }
    }

    &__text-container {
        font-style: italic;
        font-size: pxToRem(14);
        margin-left: 5px;
    }

    &__highlight {
        @include themify($themes) {
            color: themed("primaryColor");
        }
    }

    &__accept {
        font-size: pxToRem(12);
        margin-top: 5px;
    }

    &__file-list-container {
        margin-top: 15px;
    }

    &__file-list-item {
        border: 1px solid $gray-100;
        border-radius: 8px;
        padding: 10px 15px;

        &:not(:first-child) {
            margin-top: 5px;
        }
    }

    &__file-name {
        font-size: pxToRem(12);
        margin: 0 5px;
    }
}
