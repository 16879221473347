.note-form {
    label {
        font-family: var(--ids-semantic-font-font-family-label);
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);
    }

    &__actions-container {
        padding-top: 10px;
    }
}
