@import "~assets/sass/_mixins";

.client-list-container {
    .bookmarked-projects-item-container {
        background-color: $white;

        .bookmarked-projects-item {
            border-bottom: 1px solid $grey-light;
            height: 44px;
            font-weight: 500;
            padding: 0 15px;
            cursor: pointer;

            @include themify($themes) {
                color: themed("primaryColor");
            }

            .arrow-in-circle-right-empty {
                display: none !important;
            }

            &:hover,
            &.selected {
                @include themify($themes) {
                    background-color: themed("primaryBackgroundColor");
                }

                .arrow-in-circle-right-empty {
                    display: block !important;
                }
            }
        }
    }
}
