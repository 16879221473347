@import "~assets/sass/_mixins";

.help-tips-form {
    &__header {
        @include themify($themes) {
            background-color: themed("primaryBackgroundColor");
        }
    }

    &__header-text {
        font-weight: 500;
        text-transform: uppercase;

        &:before {
            @include themify($themes) {
                color: themed("primaryColor");
            }
        }
    }

    &__body {
        padding: 30px 0;
    }

    &__title {
        font-weight: 500;
        font-size: pxToRem(32);
        line-height: 32px;
        padding: 0 25px;
        margin-bottom: 25px;
    }

    &__content-container {
        padding: 0 25px;
    }

    &__title-field,
    &__content-field {
        padding: 0 25px;
    }

    &__content-label {
        margin-bottom: 8px;
    }

    .ck-editor {
        display: flex;
        flex-direction: column;

        flex: 1;
        overflow: hidden;

        &__main {
            flex: 1;
            overflow: auto;
        }

        &__editable {
            height: 100%;
        }
    }

    &__footer {
        border-top: 1px solid $grey-light;
    }
}
