@import "~assets/sass/_mixins";

.window {
    &.split-view {
        flex-direction: row;
    }

    .split-window-left {
        border-right: 1px solid $grey-dark;
    }

    .split-window-right {
        border-left: 1px solid $grey-dark;
    }
}
