@import "~assets/sass/_mixins";

.tile {
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;

    &:hover {
        background-color: var(--ids-semantic-background-color-brand-a-subtlest-hover);
        cursor: pointer;
    }
}
