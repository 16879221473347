@import "~assets/sass/_mixins";

.action-label {
    font-size: pxToRem(14);
    line-height: 22px;
    cursor: pointer;

    @include themify($themes) {
        color: themed("primaryColor");
    }
}
