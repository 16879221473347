@import "~assets/sass/_mixins";

.projections-selection-sidebar {
    font-family: var(--ids-semantic-font-font-family-label);
    background-color: $white;
    border-right: 1px solid $grey-light;
    width: 390px;

    &__action-running {
        border-top: 1px solid $grey-light;
        padding: 5px 0;
    }

    &__main-header-overview {
        color: var(--ids-semantic-ink-color-neutral-subtle-onlight);
        font-size: pxToRem(12);
        padding-top: 16px;
        padding-bottom: 4px;
        padding-left: 30px;
        text-transform: uppercase;
    }

    &__main-header-container {
        cursor: pointer;
        padding: 0 20px;
        border: 2px solid transparent;

        &:hover {
            background-color: var(--ids-semantic-background-color-brand-a-subtlest-hover);
        }

        &:focus {
            border: 2px solid var(--ids-semantic-focus-ring-color-brand-a);
        }

        &.selected {
            background-color: var(--ids-semantic-background-color-brand-a-subtlest-active);
            color: var(--ids-semantic-ink-color-brand-a-accent);
            border-left: 4px solid var(--ids-semantic-border-color-brand-a-accent);

            &:hover {
                border-top: 2px solid var(--ids-semantic-background-color-brand-a-subtlest-hover);
                border-right: 2px solid var(--ids-semantic-background-color-brand-a-subtlest-hover);
                border-bottom: 2px solid var(--ids-semantic-background-color-brand-a-subtlest-hover);
                background-color: var(--ids-semantic-background-color-brand-a-subtlest-hover);
            }

            .with-aura span {
                background-color: var(--ids-semantic-background-color-brand-a-subtle-hover);
            }
        }
    }

    &__projection {
        border-width: 2px 2px 2px 4px;
        border-style: solid;
        border-color: transparent;
        position: relative;
        padding-right: 30px;
        padding-left: 60px;

        &:hover {
            background-color: var(--ids-semantic-background-color-brand-a-subtlest-hover);
        }

        &:focus {
            border-left-width: 2px;
            border-color: var(--ids-semantic-focus-ring-color-brand-a);
        }

        &.selected {
            background-color: var(--ids-semantic-background-color-brand-a-subtlest-active);
            border-left-color: var(--ids-semantic-border-color-brand-a-accent);

            &:hover {
                border-top: 2px solid var(--ids-semantic-background-color-brand-a-subtlest-hover);
                border-right: 2px solid var(--ids-semantic-background-color-brand-a-subtlest-hover);
                border-bottom: 2px solid var(--ids-semantic-background-color-brand-a-subtlest-hover);
                background-color: var(--ids-semantic-background-color-brand-a-subtlest-hover);
            }

            .projections-selection-sidebar__projection-name {
                color: var(--ids-semantic-ink-color-brand-a-accent);
                font-weight: 500;
            }
        }
    }

    &__status-container {
        min-width: 12px;
        min-height: 12px;
        width: 12px;
        height: 12px;
    }

    &__status-circle {
        border-radius: 50%;
        width: 100%;
        height: 100%;

        @each $status, $color in $modelStatus {
            &.#{$status} {
                @include themify($themes) {
                    border: 1px solid themed(map-get($color, borderColor));
                    background-color: themed(map-get($color, backgroundColor));
                }
            }
        }

        @each $status, $color in $componentModelStatus {
            &.#{$status} {
                @include themify($themes) {
                    border: 1px solid themed(map-get($color, borderColor));
                    background-color: themed(map-get($color, backgroundColor));
                }
            }
        }
    }

    &__projection-name {
        max-width: 260px;
        height: 35px;
        padding: 0 8px;
        word-wrap: break-word;
    }

    .accordion-header {
        height: 45px;
        padding: 0 30px;
    }

    .with-aura span {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 6px;
        margin-right: 6px;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background-color: var(--ids-semantic-background-color-brand-a-subtlest-active);
    }

    &__refresh-icon {
        margin-right: 10px;
    }
}
