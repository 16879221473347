@import "~assets/sass/_mixins";

$model-list-column-width: (
    all: 40px,
    checkbox: 110px,
    model-status: 150px,
    territory: 110px,
    fuel: 110px,
    sector: 110px,
    last-calculated-on: 170px,
    required: 100px,
    calculation-status: 145px,
    results: 36px,
);

$combined-model-list-column-width: (
    // size of the icon width, left padding and additional 2 px because otherwise outline gets cut out
    bookmarked: 43px,
    actions: 28px
);

$model-list-column-padding: 8px;

.models {
    &__progress-bar {
        font-size: pxToRem(14);
        line-height: 22px;
    }

    &__action-title {
        font-weight: 500;
        font-size: pxToRem(16);
        line-height: 22px;
    }

    .model-list {
        .list-item-row {
            height: 54px;
        }

        #header,
        .column {
            &-model-status,
            &-territory,
            &-fuel,
            &-sector,
            &-last-calculated-on,
            &-required,
            &-calculation-status,
            &-results {
                padding-left: $model-list-column-padding;
            }
        }

        .column {
            @each $column, $size in $model-list-column-width {
                &-#{$column} {
                    max-width: $size;
                }
            }

            &-model-status {
                ids-tag {
                    display: flex;
                }
            }

            &-calculation-status {
                .indeterminate-progress-bar {
                    .top-container {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .column-checkbox {
            .checkbox-input-container {
                .label {
                    @include themify($themes) {
                        color: themed("primaryColor");
                    }
                }
            }
        }
    }

    &__actions {
        margin: 20px 0 0 0;

        &:last-child {
            padding: 0 0 8px 0;
        }
    }

    &__combined-models-container {
        margin-top: 40px;

        .combined-models-list {
            #header,
            .column {
                &-combination-name {
                    padding-left: $model-list-column-padding;
                }
            }

            .column {
                @each $column, $size in $combined-model-list-column-width {
                    &-#{$column} {
                        max-width: $size;
                    }
                }
            }
        }
    }

    &__update-action-container {
        margin-top: 20px;
    }

    .dropdown-item {
        &:last-child {
            color: $red-medium;

            .delete-trash-empty {
                &:before {
                    color: $red-medium;
                }
            }
        }
    }
}

.model-actions-dropdown-component {
    font-weight: 500;
    font-size: pxToRem(14);
    line-height: 22px;

    .icon-more {
        margin-right: 5px;
    }
}
