@import "~assets/sass/_mixins";

.sidenav-container {
    overflow: hidden;
    position: relative;

    &--active {
        z-index: 0; // Fixes issue with hiding bottom tab shadow
    }
}

.sidenav-backdrop {
    z-index: $fade-z-index + 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &--no-pointer-events {
        pointer-events: none;
    }
}

.sidenav {
    background-color: $white;
    position: relative;
    top: 0;
    bottom: 0;
    flex-shrink: 0;
    z-index: $fade-z-index + 2;
    overflow-x: hidden;

    @include box-shadow-common($shadow-outer);

    &--left {
        left: 0;
    }

    &--right {
        right: 0;
    }

    &--small {
        width: 400px;
    }

    &--medium {
        width: 712px;
    }

    &--large {
        width: 75%;
    }
}

.sidenav-footer {
    z-index: $fade-z-index + 1;
    position: relative;
    padding: 20px 25px 25px 25px;
}
