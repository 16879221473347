@import "~assets/sass/_mixins";

.study-case {
    &__header-container {
        height: 35px;
        margin-bottom: 15px;
    }

    &__title {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);

        margin-right: 10px;

        @include semanticFontFixedHeading5Bold;
    }

    &__help-tip {
        max-width: 850px;
        padding-right: 0;

        &:hover {
            visibility: visible;
            z-index: 3;
            pointer-events: auto;

            .tooltip-arrow:before {
                visibility: visible;
            }
        }
    }

    &__tooltip-content-wrapper {
        height: 300px;
        overflow-y: auto;
    }

    &__actions-container {
        margin-top: 20px;
    }
}
