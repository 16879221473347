.trash-bin-log {
    #header,
    .column {
        &-modified {
            max-width: 300px;
        }

        &-modified-by {
            max-width: 250px;
        }

        &-actions {
            max-width: 250px;
        }

        &-modified,
        &-modified-by,
        &-actions {
            padding: 0 0 0 13px;
        }
    }
}
