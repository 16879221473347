@import "~assets/sass/_variables";

.html-report {
    .report-container {
        border-top: 1px solid $grey-light;
        border-bottom: 1px solid $grey-light;
    }

    iframe {
        border: none;
        height: 100%;
    }
}
