@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";

.numeric-input-container {
    position: relative;

    &__arrows {
        position: absolute;
        left: 65px;
        bottom: 0;
        height: 40px;

        &.disabled,
        &.read-only {
            .shevron-small-up-expand-less,
            .shevron-small-down-expand-more {
                color: $grey-white-dark;
            }
        }

        &.error {
            .shevron-small-up-expand-less:not(.icon-wrap-disabled),
            .shevron-small-down-expand-more:not(.icon-wrap-disabled) {
                color: $red-medium;
            }
        }

        &__separator {
            border-bottom: 1px solid $grey-medium;
            margin: 0 5px;
        }

        .shevron-small-up-expand-less,
        .shevron-small-down-expand-more {
            height: 20px;
            line-height: 20px;
        }
    }
}
