@import "~assets/sass/_mixins";

.icon-wrap {
    position: relative;
    width: 24px;
    height: 24px;
    color: $grey-dark;
    line-height: 24px;
    border-radius: 50%;

    &.with-icon {
        font-size: 18px;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.icon-small {
        width: 16px;
        height: 16px;
        line-height: 16px;

        &.with-icon {
            font-size: 10px;
        }
    }

    &.icon-medium {
        width: 30px;
        height: 30px;
        line-height: 30px;

        &.with-icon {
            font-size: 24px;
        }
    }

    &.icon-big {
        width: 72px;
        height: 72px;
        line-height: 72px;

        &.with-icon {
            font-size: 40px;
        }
    }

    &.clickable {
        @include themify($themes) {
            color: themed("primaryColor");
        }

        &:not(.error):not(.success):not(.warning):not(.without-background):not(.inverse):hover {
            @include themify($themes) {
                background-color: themed("primaryBackgroundColor");
            }
        }
    }

    &.active {
        @include themify($themes) {
            background-color: themed("primaryBackgroundColor");
            color: themed("primaryColor");
        }

        &.secondary {
            @include themify($themes) {
                background-color: themed("secondaryBackgroundColor");
                color: themed("secondaryColor");
            }
        }
    }

    &.error {
        color: $red-700;
    }

    &.success {
        color: $green;
    }

    &.warning {
        color: $yellow-dark;
    }

    &.inverse.clickable {
        @include themify($themes) {
            background-color: themed("primaryColor");
            color: themed("primaryBackgroundColor");
        }
    }
}
