@import "~assets/sass/_mixins";

.change-password-form {
    &__header {
        font-weight: 500;
        font-size: pxToRem(16);
        line-height: 22px;

        @include themify($themes) {
            color: themed("primaryColor");
        }
    }

    &__error-message {
        margin-bottom: 25px;
    }

    &__inputs {
        margin: 25px 0;
    }

    &__redirect {
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;

        @include themify($themes) {
            color: themed("primaryColor");
        }
    }
}
