@import "~assets/sass/_mixins";

.dropdown-item {
    border-bottom: 1px solid $grey-light;
    background-color: $white;
    font-size: pxToRem(14);
    text-align: left;
    padding: 10px 8px;

    &:last-child {
        border: none;
    }

    &:hover:not(.disabled) {
        cursor: pointer;

        @include themify($themes) {
            background-color: themed("primaryBackgroundColor");
        }
    }

    &.selected {
        @include themify($themes) {
            background-color: themed("primaryBackgroundColor");
        }
    }

    .checkbox-input-container {
        margin-right: 8px;
    }

    &.disabled {
        background-color: $gray-100;
        color: $grey-white-dark;
    }

    &.danger {
        color: $red-medium;

        .icon-wrap:before {
            color: $red-medium;
        }
    }
}

.dropdown-component__filter {
    .dropdown-item {
        border-radius: 8px 8px 0 0;

        &:hover {
            background-color: $white;
            cursor: default;
        }
    }
}
