.window-header-icon-group {
    .split-button {
        transform: rotate(90deg);

        @media screen and (min-width: 1025px) and (max-width: 1439px) {
            &:not(.is-disabled) {
                display: none;
            }
        }

        &.is-disabled {
            display: none;

            @media screen and (min-width: 1025px) and (max-width: 1439px) {
                display: block;
            }
        }
    }

    .fullscreen-button {
        margin-left: 8px;
    }

    &__user-avatar-clickable {
        cursor: pointer;
    }
}
