.label {
    display: block;
    // font-size: pxToRem(12);
    line-height: 16px;

    &.disabled {
        color: var(--ids-semantic-ink-color-neutral-subtlest);
    }

    &.error {
        color: var(--ids-semantic-ink-color-critical-subtlest);
    }

    // Above Field Group element
    + .field-group {
        margin-top: 8px;
    }

    // Above Input element
    + .input-container__wrapper {
        margin-top: 8px;
    }

    // Above Textarea element
    + .textarea-field {
        margin-top: 8px;
    }

    // Above Dropdown element
    + .dropdown-container {
        margin-top: 8px;
    }

    // Above Upload File element
    + .upload-file {
        margin-top: 8px;
    }

    // Above Date Picker element
    + .react-datepicker-wrapper {
        margin-top: 8px;
    }

    // Next to Switcher element (left)
    + .switcher-container {
        margin-left: 8px;
    }

    // Next to Component Dropdown element (left)
    + .component-with-dropdown {
        margin-left: 8px;
    }

    // Next to Tag Dropdown element (left)
    + .tag-with-dropdown {
        margin-left: 8px;
    }

    // Next to Tag Status element (left)
    + .status-tag {
        margin-left: 8px;
    }

    // Next to Ids Tag element (left)
    + .aeg-tag-wrapper {
        margin-left: 8px;
    }
}

// Next to Input element (right)
.input-container + .label {
    margin-left: 8px;
}
