@import "~assets/sass/_animations";

.vi-icon {
    &.error {
        fill: var(--ids-semantic-ink-color-critical-subtlest);
    }

    &.success {
        fill: var(--ids-semantic-ink-color-success-subtlest);
    }

    &.warning {
        fill: var(--ids-semantic-ink-color-warning-subtlest);
    }

    &.disabled {
        fill: var(--ids-semantic-ink-color-neutral-subtlest);
    }

    &.clickable {
        fill: var(--ids-semantic-ink-color-brand-a-accent);
    }

    &.rotate {
        @include animation-rotation;
    }
}
