@import "~assets/sass/_mixins";

.ui-library {
    padding: 0 30px;

    &__element-group {
        background-color: $white;
        padding: 25px;
        margin: 20px 0;
        border-radius: 8px;

        @include box-shadow-common($shadow-outer-hover);

        &__title {
            font-weight: 500;
            font-size: pxToRem(24);
            line-height: 32px;
            color: $grey-dark;
            margin-bottom: 20px;
        }

        &__sub-title {
            font-weight: 700;
            font-size: pxToRem(12);
            line-height: 18px;
            text-transform: uppercase;
            margin-bottom: 20px;

            @include themify($themes) {
                color: themed("primaryColor");
            }
        }

        &__column {
            min-width: 280px;

            &:not(:first-child) {
                margin-left: 30px;
            }
        }

        &__textarea {
            .textarea-container {
                @include calc(width, "100% / 4 - 20px");
            }
        }

        &__dropdown {
            width: 280px;

            &:not(:first-child) {
                margin-left: 30px;
            }
        }
    }
}

// TODO: Remove this piece of code when UI library is refactored
.ui-library-sub-title {
    margin-bottom: 22px;
    font-size: pxToRem(12);
    line-height: 18px;
    font-weight: 700;
    text-transform: uppercase;
    @include themify($themes) {
        color: themed("themeLightColor");
    }
}

.flexbox-row {
    + .flexbox-row {
        margin-top: 50px;
    }

    .row {
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        > .switcher,
        > .status-tag,
        > .icon-wrap {
            display: inline-block;
            vertical-align: top;

            &:not(:last-child) {
                margin-right: 17px;
            }
        }
    }
}

.tab-list:not(.tab-list-ghost) {
    display: inline-block;
    padding: 15px;
    background-color: $gray-100;
}

.tooltip {
    position: relative;
}
