.modules-container {
    &__cards-container {
        padding: 20px 30px;
    }

    &__breadcrumbs-container {
        margin-bottom: 30px;
    }

    &__breadcrumbs-model-name {
        margin-left: 5px;
    }

    &__actions-container {
        margin-bottom: 20px;
    }
}
