@import "~assets/sass/_mixins";

.dashboard-panel {
    border: 1px solid $grey-light;
    background-color: $white;
    overflow: hidden;
    border-radius: 18px;

    .panel-header-container {
        padding: 20px;

        .panel-header {
            .panel-title {
                font-family: var(--ids-semantic-font-font-family-label);
                font-size: pxToRem(18);
                font-weight: 500;
                line-height: 21px;
                margin-right: 5px;
            }
        }
    }

    .panel-body {
        padding: 0 15px;
        margin-bottom: 20px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &.no-padding {
        .panel-body {
            padding: 0;
        }
    }
}
