@import "~assets/sass/_mixins";

.how-to-import {
    &__title-block {
        margin-bottom: 18px;

        &--title {
            font-weight: 500;
        }
    }

    ul {
        list-style-type: disc;
        padding: 5px 0 10px 35px;
    }

    li {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    a {
        display: block;
        margin: 0 0 10px 0;

        .download {
            font-size: pxToRem(14);
        }
    }
}
