@import "~assets/sass/_mixins";

$directory-list-column-width: (
    last-modified: 170px,
);

$file-list-column-width: (
    name: 150px,
    type: 150px,
    creation-date: 170px,
    extension: 100px,
    size: 100px,
    actions: 72px,
);

$column-padding: 8px;

.file-list {
    #header,
    .column {
        &-name,
        &-type,
        &-extension,
        &-creation-date,
        &-size {
            padding-left: $column-padding;
        }
    }

    .column {
        @each $column, $size in $directory-list-column-width {
            &-#{$column} {
                min-width: $size;
            }
        }

        &-filter {
            padding: 0;
        }
    }

    .column {
        @each $column, $size in $file-list-column-width {
            &-#{$column} {
                min-width: $size;
            }
        }
    }

    &__folder-name {
        font-weight: 500;
        font-size: pxToRem(14);
        line-height: 22px;
        margin-left: 15px;

        @include themify($themes) {
            color: themed("primaryColor");
        }
    }
}
