@import "~assets/sass/_mixins";

.avoided-costs-tab-content {
    background-color: $white;

    margin: 0 20px;

    .avoided-costs-list-wrapper {
        padding: 0 20px;

        .avoided-costs-list {
            #cst-split-0 {
                flex: 0 0 fit-content;
                max-width: 40%;
            }

            #cst-split-1 {
                flex: 0 1 fit-content;
            }

            .list-item-row-selected {
                @include themify($themes) {
                    background-color: themed("primaryBackgroundColor");
                }
            }

            .list-header-row {
                z-index: 2;
                position: sticky;
                top: 0;
            }

            // Hide filter for years
            .list-search-headers-row {
                .column-filter.filter.year {
                    visibility: hidden;
                }
            }

            .column-header,
            .item-value,
            .column-filter {
                min-width: 160px;

                &.year {
                    min-width: 90px;
                    width: 90px;

                    input {
                        font-size: pxToRem(14);
                        text-align: right;
                    }
                }

                &.column {
                    &-selected {
                        min-width: 50px;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .column-header {
                padding-left: 8px;
            }

            // Years headings
            .wrap-scroll-items {
                .column-content {
                    justify-content: flex-end;
                    padding-right: 10px;
                }
            }
        }
    }

    .avoided-costs-actions {
        &-top {
            z-index: 4;

            .cost-type {
                .filter-cost-type {
                    width: 280px;
                }
            }

            .line-loss {
                margin: 0 0 0 20px;
            }

            .peak-season {
                margin: 0 0 0 20px;
            }
        }

        &-bottom {
            min-height: 40px; // height of dropdown
            z-index: 3;
            margin: 10px 0 0 0;

            .bulk-edit {
                margin: 0 0 0 20px;
            }

            .delete-rows {
                margin: 0 0 0 20px;
            }

            .errors {
                margin: 0 0 0 20px;

                .filter-errors {
                    width: 300px;
                }
            }

            .assign-sector {
                margin: 0 0 0 20px;

                .sector {
                    width: 140px;
                }
            }
        }
    }

    .actions-label {
        font-size: pxToRem(12);
        line-height: 16px;
    }

    .actions-margin {
        margin: 0 10px 0 0;
    }

    .avoided-costs-subheader {
        margin: 20px 20px 10px 20px;
    }
}

.custom-list.avoided-costs-list .paging-wrap {
    padding-left: 25px;
}
