@import "~assets/sass/_mixins";

.variables {
    position: relative;

    &.project-variables {
        .variable-field {
            @include calc(width, "100% / 3 - 6px");
        }
    }

    &.model-variables {
        .variable-field {
            @include calc(width, "100% / 5 - 6px");
        }
    }

    &.module-variables {
        z-index: 1;

        .variable-field {
            @include calc(width, "100% / 2 - 6px");
        }
    }

    &__warning {
        background-color: $yellow-white;
        font-size: pxToRem(14);
        border-radius: 10px;

        .icon-with-label {
            font-size: pxToRem(14);
        }
    }

    &__info {
        font-size: pxToRem(14);

        .icon-with-label {
            font-size: pxToRem(14);
        }
    }

    &__group-name {
        font-size: pxToRem(13);
        font-weight: 500;
    }

    &__actions {
        padding: 0 0 8px 0;
        margin: 20px 0 0 0;
    }
}
