@import "~assets/sass/_mixins";

.tag-with-custom-arrow {
    @each $status, $color in $projectStatus {
        &.#{$status} {
            .icon-triangle {
                @include themify($themes) {
                    border-top-color: themed(map-get($color, borderColor));
                }
            }
        }
    }

    @each $status, $color in $modelStatus {
        &.#{$status} {
            .icon-triangle {
                @include themify($themes) {
                    border-top-color: themed(map-get($color, borderColor));
                }
            }
        }
    }

    @each $status, $color in $componentModelStatus {
        &.#{$status} {
            .icon-triangle {
                @include themify($themes) {
                    border-top-color: themed(map-get($color, borderColor));
                }
            }
        }
    }

    @each $status, $color in $modelInputStatus {
        &.#{$status} {
            .icon-triangle {
                @include themify($themes) {
                    border-top-color: themed(map-get($color, borderColor));
                }
            }
        }
    }
}
