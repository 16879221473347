@import "~assets/sass/_variables";

.client-create {
    #header,
    .column {
        @each $column, $size in $new-territory-column-width {
            &-#{$column} {
                max-width: $size;
            }
        }
    }

    #header-alias {
        margin-left: $new-territory-column-padding;
    }

    #header-code {
        margin-right: $new-territory-column-padding;
    }

    .column {
        &-alias,
        &-code {
            padding: 0 $new-territory-column-padding;
        }
    }
}
