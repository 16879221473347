.scenario-log {
    /*
        Since Model names can be quite long,
        made rows taller.
    */
    .scenario-log-row {
        height: 54px;
    }

    #header,
    .column {
        &-action-name,
        &-started,
        &-user,
        &-execution-time,
        &-result {
            padding: 0 0 0 13px;
        }

        &-projection-name {
            max-width: 260px;
        }

        &-action-name {
            max-width: 160px;
        }

        &-started {
            max-width: 160px;
        }

        &-user {
            max-width: 160px;
        }

        &-execution-time {
            min-width: 160px;
            max-width: 200px;
        }

        &-result {
            max-width: 160px;
        }

        &-note {
            max-width: 55px;
            margin: 0 13px 0 0;

            &.item-value {
                overflow: visible;
            }
        }

        &-filter {
            padding: 0;
        }
    }
}
