@import "~assets/sass/_mixins";

.status-msg {
    position: relative;
    font-size: pxToRem(12);
    overflow: hidden;

    &.error {
        color: $red-medium;

        .with-icon {
            &:before {
                color: $red-medium;
            }
        }

        &.large-msg {
            background: $red-gradient;

            &:after {
                content: "Error";
            }
        }
    }

    &.success {
        color: $green;

        .with-icon {
            &:before {
                color: $green;
            }
        }

        &.large-msg {
            background: $green-gradient;

            &:after {
                content: "Success";
            }
        }
    }

    &.warning {
        color: $yellow-dark;

        .with-icon {
            &:before {
                color: $yellow-dark;
            }
        }

        &.large-msg {
            background: $yellow-gradient;

            &:after {
                content: "Warning";
            }
        }
    }

    &.large-msg {
        padding: 20px 20px 40px;

        &:after {
            background-color: $white;
            position: absolute;
            left: 50%;
            bottom: -5px;
            width: 100px;
            font-weight: 500;
            font-size: pxToRem(16);
            padding: 5px 0;
            transform: translateX(-50%);
            border-radius: 8px;
        }
    }

    .icon-with-label {
        font-size: pxToRem(12);
    }

    // Below StatusMsg element
    + .status-msg {
        margin-top: 8px;
    }
}

.input-container {
    // Below Input element
    + .status-msg {
        margin-top: 8px;
    }
}

.upload-file-container {
    // Below UploadFile element
    + .status-msg {
        margin-top: 8px;
    }
}

.textarea-field {
    // Below Textarea element
    ~ .status-msg {
        margin-top: 8px;

        &:not(.error) {
            color: $grey-dark;
        }
    }
}

.dropdown-component {
    // Below Dropdown element
    + .status-msg {
        margin-top: 8px;
    }
}

.field-group-container {
    // Below FieldGroup element and
    // only apply margin to error messages
    + .error {
        margin-top: 8px;
    }
}

.model-inputs-table-selection {
    // Below ModelInputsTableSelection component
    + .status-msg {
        margin-top: 8px;
    }
}
