@import "~assets/sass/_mixins";

.field-group-container {
    .field-group {
        display: flex;

        &.column {
            flex-direction: column;
            row-gap: 20px;

            &.flex-wrap {
                column-gap: 20px;
            }
        }

        &.row {
            flex-direction: row;
            column-gap: 20px;

            &.flex-wrap {
                row-gap: 20px;
            }
        }
    }
}
