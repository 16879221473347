@import "~assets/sass/_mixins";

.help-tips-view {
    &__body {
        padding: 30px 0;
    }

    &__title {
        font-weight: 500;
        font-size: pxToRem(32);
        line-height: 32px;
        padding: 0 25px;
        margin-bottom: 25px;
    }

    &__content-container {
        padding: 0 25px;
    }

    &__footer {
        border-top: 1px solid $grey-light;
    }
}
