@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";
@import "~assets/sass/_functions";

.separator {
    height: 20px;
    flex-shrink: 0;

    &--vertical {
        height: 100%;
        width: 20px;
    }

    &--line {
        border: 1px solid $grey-light;
        height: initial;
        margin: 20px 0;
    }
}
