@import "~assets/sass/_mixins";

.model-notes {
    &__tab-item {
        /* @todo: remove when 1rem is 16px */
        .ids-tab-item {
            .label {
                font-size: pxToRem(14);
            }
        }
    }

    &__tab-panel {
        background: none;
        padding-top: 20px;
    }
}
