@import "~assets/sass/_mixins";

.textarea-field {
    border: 1px solid $grey-medium;
    width: 100%;
    height: 64px;
    font-size: pxToRem(14);
    vertical-align: bottom;
    padding: 10px 8px;
    border-radius: 8px;

    @include placeholder {
        font-style: italic;
        color: $grey-white-dark;
    }

    &:hover {
        @include themify($themes) {
            border-color: themed("selectionColor");
        }
    }

    &:not(.error) {
        &:focus,
        &.focus {
            @include themify($themes) {
                border-color: themed("selectionColor");
            }
        }
    }

    &:disabled {
        border-color: $grey-white-medium;
        background-color: $gray-100;
        color: $grey-white-dark;
    }

    &.read-only {
        border-color: $grey-white-medium;
        background-color: $gray-100;
        pointer-events: none;
    }

    &.error {
        border-color: $red-medium;
        color: $red-medium;
    }
}
