@import "~assets/sass/_mixins";

.icon-more {
    padding: 1px 5px;
    cursor: pointer;

    .circle {
        background-color: $grey-dark;
        width: 4px;
        height: 4px;
        margin: 0 0 3px 0;
        border-radius: 50%;

        &:last-child {
            margin: 0;
        }
    }

    &.clickable {
        .circle {
            @include themify($themes) {
                background-color: themed("primaryColor");
            }
        }
    }
}
