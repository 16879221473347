@import "~assets/sass/_mixins";

.card {
    border: 1px solid var(--ids-semantic-border-color-neutral-faint);
    background-color: var(--ids-semantic-background-color-neutral-accent-default-onlight);

    position: relative;
    padding: 15px 25px;
    transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out;

    @include box-shadow-common(var(--ids-semantic-shadow-md));

    &:hover {
        cursor: pointer;

        &:not(.selected) {
            border-color: var(--ids-semantic-border-color-brand-a-subtlest) !important;

            @include box-shadow-common(var(--ids-semantic-shadow-none));
        }
    }

    &.selected {
        border: 3px solid var(--ids-semantic-border-color-brand-a-subtle);
        background-color: var(--ids-core-color-brand-a-50);

        @include box-shadow-common(var(--ids-semantic-shadow-xs));
    }

    &.disabled {
        background-color: var(--ids-semantic-background-color-brand-b-subtle-default);
    }

    &.read-only {
        border-color: var(--ids-semantic-border-color-neutral-faint) !important;

        background: none !important;
        pointer-events: none;
    }

    &.card-in-progress,
    &.card-error,
    &.card-success {
        border-radius: 0 !important;
    }

    &__vertical-line {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    &.card-in-progress {
        .card__vertical-line {
            border: 4px solid var(--ids-semantic-border-color-brand-a-subtle);
        }
    }

    &.card-error {
        .card__vertical-line {
            border: 4px solid var(--ids-semantic-border-color-critical-subtle);
        }
    }

    &.card-success {
        .card__vertical-line {
            border: 4px solid var(--ids-semantic-border-color-success-subtle);
        }
    }

    &.card-add-new {
        border: none;

        @include box-shadow-common(var(--ids-semantic-shadow-none));
    }

    &.card-with-input {
        border: 1px solid var(--ids-semantic-border-color-brand-a-accent);

        border-radius: 8px;
        padding: 15px;
        transition: background-color 0.3s ease-out;

        @include box-shadow-common(var(--ids-semantic-shadow-none));

        &:hover {
            border-color: var(--ids-semantic-border-color-brand-a-accent) !important;
            background-color: var(--ids-semantic-background-color-brand-a-subtlest-hover);
        }

        &.selected {
            background-color: var(--ids-semantic-background-color-brand-a-subtlest-active);
            transition-duration: 0.2s;
        }
    }

    &.card-round-borders {
        border-radius: 8px 0 0 8px;
    }
}
