@import "~assets/sass/_mixins";

.project-list-container {
    &__actions {
        padding: 5px;
        margin-bottom: 25px;

        @include laptop {
            margin-bottom: 5px;
        }

        .emphasize {
            font-weight: 500;
        }
    }

    &__client-name {
        font-size: pxToRem(18);
        line-height: 24px;
    }
}
