@import "~assets/sass/_mixins";

.window-tab {
    background-color: var(
        --ids-core-color-brand-b-100
    ); // TODO: Change to --ids-semantic-background-color-brand-b-subtle-default when color is fixed
    position: relative;
    display: inline-block;
    width: 146px;
    max-height: 44px;
    height: 100%;
    margin-right: 10px;
    border-radius: 8px 8px 0 0;
    pointer-events: auto;

    &:last-child {
        margin: 0;
    }

    &.active,
    &:hover {
        background-color: var(--ids-semantic-background-color-brand-a-accent-default);
    }

    &.active {
        max-height: 48px;
    }

    &:hover:not(.home) {
        max-height: unset;
        height: auto;
    }

    &.split-view {
        width: calc(146px + 146px + 10px);

        .split-view-sync-controls {
            position: relative;
            width: 0;

            .cut-scissors-filled,
            .copy-link {
                border: 1px solid rgba(0, 0, 0, 0.07);
                background-color: var(--ids-semantic-background-color-brand-a-accent-default);
                position: absolute;
                top: 50%;
                left: -12px;
                display: none;
                color: var(--ids-semantic-ink-color-neutral-accent-ondark);
                transform: translateY(-50%);

                @include box-shadow(0, 0, 4px, 0 rgba(0, 0, 0, 0.5));
            }

            .locked.copy-link {
                display: block;
            }
        }
    }

    &:hover {
        &.split-view {
            .tab-details {
                &__content-main-title {
                    display: block;
                }

                &__content-sub-title {
                    &.with-title {
                        max-height: unset;
                        font-weight: 400;
                    }
                }
            }

            .split-view-sync-controls {
                .cut-scissors-filled,
                .copy-link {
                    display: block;
                }

                .locked.copy-link {
                    display: none;
                }
            }
        }

        .tab-details {
            &__content {
                &-icon {
                    fill: var(--ids-semantic-ink-color-neutral-accent-ondark);
                }

                &-title {
                    color: var(--ids-semantic-ink-color-neutral-accent-ondark);
                }
            }

            &__content-main-title {
                display: block;
            }

            &__content-sub-title {
                &.with-title {
                    max-height: unset;
                    font-weight: 400;
                }
            }

            &__close-box {
                position: absolute;
                right: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
}
