@import "./_functions";
@import "./_variables";

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}

@mixin box-shadow($left, $top, $radius, $color) {
    box-shadow: $left $top $radius $color;
    -webkit-box-shadow: $left $top $radius $color;
    -moz-box-shadow: $left $top $radius $color;
}

@mixin box-shadow-common($params) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

@mixin icon-iconoteka {
    display: inline-block;
    min-width: 1px;
    min-height: 1px;
    font-family: $font-family-iconoteka;
    font-weight: normal;
    font-style: normal;
}

@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin placeholder {
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

@mixin flex-wrap($wrap) {
    -webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap; // IE11 has partial support
    flex-wrap: $wrap;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin laptop {
    @media only screen and (max-width: 1609px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1610px) {
        @content;
    }
}

@mixin desktop-large {
    @media only screen and (min-width: 1710px) {
        @content;
    }
}

// IE10+ specific styles
@mixin ie {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

// Edge 12+ specific styles
@mixin ie-edge {
    @supports (-ms-ime-align: auto) {
        @content;
    }
}

// Firefox specific styles
@mixin firefox {
    @-moz-document url-prefix() {
        @content;
    }
}

@mixin webkit-scrollbar {
    &::-webkit-scrollbar {
        height: $scrollbar-size;
        width: $scrollbar-size;
        background-color: rgba(99, 110, 114, 0.07);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:vertical {
        background-color: rgba(99, 110, 114, 0.5);
        border: 2px solid #f5f5f6;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        background-color: rgba(99, 110, 114, 0.5);
        border-radius: 10px;
    }
}

@mixin webkit-scrollbar-thin {
    &::-webkit-scrollbar {
        height: $scrollbar-thin-size;
        width: $scrollbar-thin-size;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        border: none;
        border-radius: 3px;
    }
}

@mixin scrollbar-hide {
    scrollbar-width: none !important; // Firefox
    -ms-overflow-style: none !important; // IE 10+

    &::-webkit-scrollbar {
        display: none !important; // WebKit
    }
}

@mixin rotate($degrees, $axis: null) {
    $transform: rotate($degrees);
    transition: all 0.3s ease-in;

    @if $axis == x {
        $transform: rotateX($degrees);
    } @else if $axis == y {
        $transform: rotateY($degrees);
    }

    -ms-transform: $transform;
    -webkit-transform: $transform;
    transform: $transform;
}

@mixin data-grid-sticky-header {
    max-height: 100%;

    .grid-list-row-container {
        overflow-y: auto;

        .grid-list-row:last-child {
            border-bottom: none;
        }
    }
}

@mixin themify($themes) {
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key, $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }

            @content;

            $theme-map: null !global;
        }
    }
}

@mixin fade($height) {
    content: "";
    z-index: $fade-z-index;
    position: sticky;
    left: 0;
    height: $height;
    min-height: $height;
    width: 100%;
}

@mixin withFade($top: 20px, $bottom: 20px) {
    position: relative;

    @if ($top != 0) {
        &:before {
            top: 0;
            background: linear-gradient(to top, rgba(255, 255, 255, 0), white 40%);

            @include fade($top);
        }
    }

    @if ($bottom != 0) {
        &:after {
            bottom: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 40%);

            @include fade($bottom);
        }
    }
}

// Mixins from IDS (NEW)

// Fixed font headings

// Regular headings

// Add here...

// Bold headings

@mixin semanticFontFixedHeading4Bold {
    font-weight: var(--ids-semantic-font-fixed-heading4-bold-font-weight);
    text-decoration: var(--ids-semantic-font-fixed-heading4-bold-text-decoration);
    letter-spacing: var(--ids-semantic-font-fixed-heading4-bold-letter-spacing);
    font-family: var(--ids-semantic-font-fixed-heading4-bold-font-family);
    line-height: var(--ids-semantic-font-fixed-heading4-bold-line-height);
    font-size: var(--ids-semantic-font-fixed-heading4-bold-font-size);
}

@mixin semanticFontFixedHeading5Bold {
    font-weight: var(--ids-semantic-font-fixed-heading5-bold-font-weight);
    text-decoration: var(--ids-semantic-font-fixed-heading5-bold-text-decoration);
    letter-spacing: var(--ids-semantic-font-fixed-heading5-bold-letter-spacing);
    font-family: var(--ids-semantic-font-fixed-heading5-bold-font-family);
    line-height: var(--ids-semantic-font-fixed-heading5-bold-line-height);
    font-size: var(--ids-semantic-font-fixed-heading5-bold-font-size);
}

// Fixed font body

// Regular body

@mixin semanticFontFixedBody3Regular {
    font-weight: var(--ids-semantic-font-fixed-body3-regular-font-weight);
    letter-spacing: var(--ids-semantic-font-fixed-body3-regular-letter-spacing);
    text-decoration: var(--ids-semantic-font-fixed-body3-regular-text-decoration);
    font-family: var(--ids-semantic-font-fixed-body3-regular-font-family);
    line-height: var(--ids-semantic-font-fixed-body3-regular-line-height);
    font-size: var(--ids-semantic-font-fixed-body3-regular-font-size);
}

// Bold body

// Add here...

// Fixed font labels

// Regular labels

@mixin semanticFontFixedLabel2Regular {
    font-weight: var(--ids-semantic-font-fixed-label2-regular-font-weight);
    text-decoration: var(--ids-semantic-font-fixed-label2-regular-text-decoration);
    letter-spacing: var(--ids-semantic-font-fixed-label2-regular-letter-spacing);
    font-family: var(--ids-semantic-font-fixed-label2-regular-font-family);
    line-height: var(--ids-semantic-font-fixed-label2-regular-line-height);
    font-size: var(--ids-semantic-font-fixed-label2-regular-font-size);
}

// Bold labels

@mixin semanticFontFixedLabel2Bold {
    font-weight: var(--ids-semantic-font-fixed-label2-bold-font-weight);
    text-decoration: var(--ids-semantic-font-fixed-label2-bold-text-decoration);
    letter-spacing: var(--ids-semantic-font-fixed-label2-bold-letter-spacing);
    font-family: var(--ids-semantic-font-fixed-label2-bold-font-family);
    line-height: var(--ids-semantic-font-fixed-label2-bold-line-height);
    font-size: var(--ids-semantic-font-fixed-label2-bold-font-size);
}
