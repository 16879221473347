@import "~assets/sass/_mixins";

.window-header {
    color: var(--ids-semantic-ink-color-brand-b-accent);
    font-family: var(--ids-semantic-font-font-family-label);
    background-color: $white;
    padding: 0 30px;
    z-index: 1;

    @include box-shadow-common($shadow-windows-outer);

    &__body {
        height: 56px;
    }

    &__logo {
        background-image: url("https://files.insight.visiondsm.com/public/Vision-Horizontal-Signature-Blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        width: 130px;
        height: 34px;
        cursor: default;
    }

    &__vlm-logo {
        background-image: url("https://files.insight.visiondsm.com/public/Vision-Horizontal-LoadMAP-Blue.svg");
    }

    &__dropdown-container {
        + .window-header-icon-group {
            .clear-close {
                margin-left: 20px;
            }
        }
    }

    &__title {
        font-weight: var(--ids-semantic-font-fixed-label1-medium-font-weight);
        font-size: pxToRem(21);
        line-height: 150%;
    }

    &__subtitle {
        font-weight: var(--ids-semantic-font-fixed-label1-regular-font-weight);
        font-size: pxToRem(17);
        margin-left: 10px;
    }
}
