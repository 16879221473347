@import "~assets/sass/_mixins";

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker-wrapper {
    position: relative;

    &.disabled {
        &:after {
            color: $grey;
        }
    }

    &:not(.error) {
        &.read-only,
        &.focus {
            &:after {
                @include themify($themes) {
                    color: themed("primaryColor");
                }
            }
        }
    }

    &.focus:after {
        content: $icon-calendar-filled;
    }

    &.error:after {
        color: $red-medium;
    }

    &:not(.datepicker-wrapper-ghost):after {
        content: $icon-calendar-empty;
        @include icon-iconoteka;
        position: absolute;
        right: 7px;
        line-height: 32px;
        pointer-events: none;
        transform: translateY(-50%);
        top: 50%;
    }

    input[type="text"].datepicker {
        border: 1px solid $grey-medium;
        width: 100%;
        height: 40px;
        font-size: pxToRem(14);
        padding: 0 8px;
        border-radius: 8px;

        &:not(.datepicker-ghost) {
            @include placeholder {
                font-style: italic;
                color: $grey-white-dark;
            }
        }

        &.error:not(:focus) {
            border-color: $red-medium;
        }

        &.datepicker-ghost {
            border: none;
            font-weight: 700;
            pointer-events: none;

            &::placeholder {
                color: var(--ids-core-color-neutral-950);
            }
        }

        &.input-table-filter {
            border: none;
            border-radius: 0;
        }
    }
}

.react-datepicker-popper {
    z-index: $fade-z-index + 1;

    &.with-timepicker {
        @media screen and (min-width: 768px) {
            left: -50px !important;
            padding-right: 100px;
        }
    }

    &.hide-popper {
        visibility: hidden;
    }
}

.react-datepicker {
    font-family: var(--ids-semantic-font-font-family-helper);
    font-weight: var(--ids-semantic-font-fixed-body3-regular-font-weight);
    border-radius: 4px;

    @include themify($themes) {
        border: 1px solid themed("selectionColor");
    }

    .react-datepicker__triangle {
        display: none;
    }
}

.react-datepicker__month {
    margin: 0;
    padding: 5px 0;
}

.react-datepicker__header {
    border-bottom: 1px solid $grey-light;
    background-color: transparent;
    margin: 0 10px;
    padding-top: 0;
    border-radius: 4px 4px 0 0;
}

.react-datepicker__current-month {
    font-weight: 500;
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
}

.react-datepicker__navigation {
    border: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-indent: inherit;
    font-size: 18px;
    border-radius: 50%;

    .react-datepicker__navigation-icon {
        display: none;
    }

    &:before {
        @include icon-iconoteka;

        @include themify($themes) {
            color: themed("primaryColor");
        }
    }

    &.react-datepicker__navigation--next:before {
        content: $icon-shevron-keyboard-arrow-right;
    }

    &.react-datepicker__navigation--previous:before {
        content: $icon-shevron-keyboard-arrow-left;
    }
}

.react-datepicker__day {
    &:not(.react-datepicker__day--today):not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled) {
        &:hover {
            font-weight: 500;
            color: $white;

            @include themify($themes) {
                background-color: themed("primaryColor");
            }
        }
    }

    &:hover {
        border-radius: 4px;
    }

    &--keyboard-selected {
        font-weight: 500;

        @include themify($themes) {
            background-color: themed("primaryColor");
        }
    }
}

.react-datepicker__day-name,
.react-datepicker__day {
    width: 40px;
    line-height: 32px;
    font-size: 15px;
    border-radius: 4px;
}

.react-datepicker__day-name {
    line-height: inherit;
    font-size: 13px;

    .font-size-large & {
        font-size: 15px;
    }
}

.react-datepicker__day--selected {
    font-weight: 500;
    color: var(--ids-semantic-background-color-brand-a-subtlest-default);

    @include themify($themes) {
        background-color: themed("primaryColor");
    }
}

.react-datepicker__day--keyboard-selected {
    color: var(--ids-semantic-background-color-brand-a-subtlest-default);
}

.react-datepicker__day--disabled {
    color: $grey-white-dark;
}

.react-datepicker__day-names {
    padding: 0 0 5px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;

    .font-size-large & {
        font-size: 15px;
    }
}

.react-datepicker__today-button {
    border-top: 1px solid $grey-light;
    background-color: transparent;
    font-weight: 500;
    margin: 0 10px;
    padding: 15px 0;

    @include themify($themes) {
        color: themed("primaryColor");
    }
}

.react-datepicker__input-time-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.react-datepicker__time-container {
    right: -100px;
    top: -1px;
    bottom: -1px;
    display: flex;
    flex-direction: column;
    width: 100px;
    border-radius: 0 4px 4px 0;

    @include themify($themes) {
        border: 1px solid themed("primaryColor");
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
    }

    &:before {
        background-color: $white;
        left: -10px;
        width: 16px;
    }

    &:after {
        background-color: $grey-light;
        left: 0;
        width: 1px;
    }

    .react-datepicker__header--time {
        border: none;
        background-color: $white;
        margin: 0;
        padding: 10px 10px 0;

        .react-datepicker-time__header {
            padding: 10px 0;
            border-radius: 4px;
        }
    }

    .react-datepicker__time {
        flex-grow: 1;
        overflow: auto;

        .react-datepicker__time-list {
            height: auto !important;
            overflow-y: initial !important;

            &-item {
                height: auto !important;
                line-height: 25px;

                &:hover {
                    font-weight: 500;
                }
            }

            &-item--selected {
                @include themify($themes) {
                    background-color: themed("primaryColor") !important;
                }

                &:hover {
                    @include themify($themes) {
                        background-color: themed("primaryColor") !important;
                    }
                }
            }
        }
    }
}
