@import "~assets/sass/_mixins";

.trash-bin-dashboard-header {
    background-color: $white;
    padding: 50px 30px 15px 30px;

    @include box-shadow-common($shadow-emboss);

    &__label {
        font-size: pxToRem(18);
        color: var(--ids-semantic-ink-color-neutral-subtle-onlight);
    }
}
