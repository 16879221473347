.expandable-list {
    position: absolute;
    height: 0;
    visibility: hidden;

    &.expanded {
        position: relative;
        height: 100%;
        visibility: visible;
    }

    &__header {
        border-top: 1px solid #e0e0e8;
        font-weight: 500;
        font-size: pxToRem(14);
        line-height: 22px;
        padding: 10px 35px;
    }
}
