@import "~assets/sass/_mixins";

.client-attributes {
    &.client-attributes-tab {
        padding: 25px;
    }

    &__client-left-container {
        width: 50%;
    }

    &__client-right-container {
        width: 50%;
        margin-left: 30px;

        #header,
        .column {
            @each $column, $size in $new-territory-column-width {
                &-#{$column} {
                    max-width: $size;
                }
            }
        }

        #header-alias {
            margin-left: $new-territory-column-padding;
        }

        #header-code {
            margin-right: $new-territory-column-padding;
        }

        .column {
            &-alias,
            &-code {
                padding: 0 $new-territory-column-padding;
            }
        }
    }

    &__contact-section {
        margin-top: 50px;
    }

    &__contact-name-wrapper {
        @include calc(width, "50% - 15px");
    }

    &__contact-email-wrapper {
        width: 50%;
    }

    &__contact-phone-wrapper {
        width: 50%;
        margin-left: 30px;
    }
}
