@import "~assets/sass/_mixins";

.calculations-tab {
    margin-top: 15px;

    &__components-container {
        position: relative;
    }

    &__component-branch {
        border: 1px dashed var(--ids-semantic-border-color-neutral-subtlest);
        width: 0;
        position: absolute;
        top: 25px;
        left: 13px;

        @include calc(height, "100% - 22px");

        &.branch-done {
            border-style: solid;
            border-color: var(--ids-semantic-border-color-success-subtlest);
        }
    }

    &__icon {
        fill: var(--ids-semantic-ink-color-neutral-subtlest);

        &.icon-in-progress {
            fill: var(--ids-semantic-ink-color-brand-a-accent);
        }

        &.icon-error {
            fill: var(--ids-semantic-ink-color-critical-subtle);
        }

        &.icon-done {
            fill: var(--ids-semantic-ink-color-success-subtlest);
        }
    }

    &__name {
        color: var(--ids-semantic-ink-color-neutral-subtle-onlight);

        margin-left: 10px;

        @include semanticFontFixedLabel2Regular;
    }

    &__steps-container {
        padding: 15px 0;
        margin-left: 30px;
    }

    &__step {
        position: relative;

        &:not(:first-child) {
            margin-top: 15px;
        }
    }

    &__step-branch {
        border: 1px dashed var(--ids-semantic-border-color-neutral-subtlest);
        width: 0;
        position: absolute;
        top: 25px;
        left: 13px;

        @include calc(height, "100% - 7px");

        &.branch-done {
            border-style: solid;
            border-color: var(--ids-semantic-border-color-success-subtlest);
        }
    }
}
