// TODO: Remove when UI Library is refactored completely
.main-grid-wrap {
    margin: 0 auto;
    width: 100%;

    &:not(.responsive) {
        padding-left: 50px;
        padding-right: 50px;
        min-width: $site-min-width;
        max-width: $site-max-width;
    }

    &.responsive {
        padding-left: 50px;
        padding-right: 50px;
        min-width: $site-min-width;
        max-width: $site-max-width;
    }

    .layout-full-screen & {
        min-width: initial;
        max-width: initial;
        padding-left: 30px;
        padding-right: 30px;
    }

    @include desktop {
        padding-left: 90px;
        padding-right: 90px;
    }

    .split-view & {
        padding-left: 30px;
        padding-right: 30px;
        min-width: auto;
    }

    .flexbox-row {
        display: flex;
        flex-direction: row;
        margin-left: -10px;

        @include calc(width, "100% + 20px");

        + .flexbox-row {
            margin-top: 20px;
        }
    }

    .flexbox-col {
        margin: 0 10px;
        flex-basis: 0;
        flex-grow: 1;

        &.static {
            flex: 0 0 380px;
        }
    }
}
