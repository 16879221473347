@import "~assets/sass/_mixins";

.inputs-tab {
    &__header {
        color: var(--ids-semantic-ink-color-neutral-accent-onlight);

        height: 35px;
        margin-bottom: 15px;

        @include semanticFontFixedHeading5Bold;

        &:not(:first-child) {
            margin-top: 15px;
        }
    }

    .module-input-row {
        height: 58px;

        &__sheet-name {
            @include semanticFontFixedLabel2Bold;
        }
    }
}
