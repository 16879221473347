@import "~assets/sass/_mixins";

$radius: calc($progress-bar-height / 2);

.progress-bar {
    background-color: $grey-light;
    width: 100%;
    height: $progress-bar-height;
    overflow: hidden;
    border-radius: $radius;

    .filler {
        height: 100%;

        @include themify($themes) {
            background-color: themed("primaryColor");
        }

        @include transition(width 0.2s ease-in);
    }
}

.determinate-progress-bar {
    .top-container {
        margin-bottom: 8px;

        .top-element {
            padding-right: 5px;
        }
    }
}

.indeterminate-progress-bar {
    .top-container {
        margin-bottom: 8px;

        .top-element {
            padding-right: 5px;
        }
    }

    .filler {
        width: 200%;
        animation: progressbar 2.5s linear infinite;

        @include themify($themes) {
            background-image: repeating-linear-gradient(
                135deg,
                transparent,
                transparent 5px,
                themed("calculationSecondaryColor") 5px,
                themed("calculationSecondaryColor") 10px
            );
        }
    }

    .progress-bar-with-steps {
        margin-right: 2px;

        .progress-bar {
            border-radius: 0;

            .filler {
                &.todo {
                    background: none !important;
                    width: unset;
                    animation: none;
                }

                &.done {
                    width: unset;
                    animation: none;

                    @include themify($themes) {
                        background: none;
                        background-color: themed("primaryColor");
                    }
                }
            }
        }

        .icon-with-label {
            font-size: pxToRem(12);
            margin-top: 8px;

            &.repeat-empty {
                &:before {
                    @include themify($themes) {
                        color: themed("primaryColor");
                    }
                }
            }

            &.check-done {
                &:before {
                    color: $green;
                }
            }
        }

        &:first-child {
            .progress-bar {
                border-radius: 0;
                border-top-left-radius: $radius;
                border-bottom-left-radius: $radius;
            }
        }

        &:last-child {
            margin: 0;

            .progress-bar {
                border-radius: 0;
                border-top-right-radius: $radius;
                border-bottom-right-radius: $radius;
            }
        }
    }

    .bottom-element {
        margin-top: 8px;
    }
}

@keyframes progressbar {
    from {
        background-position-x: -113px;
    }
    to {
        background-position-x: 0;
    }
}
