@import "~assets/sass/_variables";

.radio-input-container {
    &:hover {
        .input-container {
            &:not(.checked) {
                .input-field {
                    &:before {
                        color: var(--ids-semantic-ink-color-brand-a-accent);
                    }
                }
            }
        }
    }

    .input-container {
        .input-field {
            &:before {
                content: $icon-circle-radio-button-unchecked-empty;

                color: var(--ids-semantic-ink-color-neutral-subtle-onlight);

                width: 24px;
                height: 24px;
                font-size: 18px;
                line-height: 24px;
            }

            &:disabled,
            &.read-only {
                &:before {
                    color: $grey-white-medium !important;
                }
            }

            &:not(:disabled):not(.read-only) {
                cursor: pointer;
            }
        }

        &.checked {
            .input-field {
                &:before {
                    content: $icon-circle-radio-button-checked-filled;

                    color: var(--ids-semantic-ink-color-brand-a-accent);
                }
            }
        }
    }

    .label {
        &:not(.disabled):not(.read-only) {
            cursor: pointer;
        }
    }
}
