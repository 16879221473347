@import "~assets/sass/_mixins";

.report {
    overflow: hidden;

    .report-actions {
        padding: 5px 35px;

        &__client-approvement {
            margin-right: 20px;
        }

        &__client-approvement-btn {
            button {
                font-weight: var(--ids-semantic-font-fixed-label1-regular-font-weight);
            }
        }

        &__refresh {
            font-weight: var(--ids-semantic-font-fixed-label1-regular-font-weight);
            font-size: pxToRem(12);
        }
    }

    .report-body {
        overflow: hidden;

        @include calc(height, "100% - 42px");

        @include themify($themes) {
            border-top: 1px solid $grey-light;
            border-bottom: 1px solid $grey-light;
        }

        iframe {
            border: none;
        }
    }
}
