@import "~assets/sass/_mixins";

.modify-avoided-costs {
    // NOTE: Removing this property will cause overflow
    // and year columns to not be visible
    max-width: 100vw;

    .avoided-costs-message-box {
        margin: 0 20px;

        .avoided-costs-message {
            .message {
                padding-left: 30px;

                &.error {
                    color: $red-medium;
                }
            }
        }

        + .avoided-costs-header {
            margin: 0 20px 0 20px;
        }
    }

    .avoided-costs-header {
        margin: 20px 20px 0 20px;

        .avoided-costs-sector-tabs {
            .tab-list {
                width: unset;

                .tabs .tab {
                    text-transform: unset;
                }
            }
        }

        .avoided-costs-details {
            padding: 0 0 5px 0;

            .latest-update {
                font-size: pxToRem(12);
                line-height: 22px;
                margin: 0 0 0 10px;
            }
        }
    }
}
