@import "~assets/sass/_animations";

.with-icon {
    position: relative;

    &:before,
    &:after {
        @include icon-iconoteka;
    }

    &.rotate {
        @include animation-rotation;
    }

    /* List of icons */

    &.add-create-plus-circle-filled:before {
        content: $icon-add-create-plus-circle-filled;
    }

    &.arrow-in-circle-left-empty:before {
        content: $icon-arrow-in-circle-left-empty;
    }

    &.arrow-in-circle-right-empty:before {
        content: $icon-arrow-in-circle-right-empty;
    }

    &.arrow-thin-right:before {
        content: $icon-arrow-thin-right;
    }

    &.assignment:before {
        content: $icon-assignment;
    }

    &.assignment-self:before {
        content: $icon-assignment-self;
    }

    &.attachment:before {
        content: $icon-attachment;
    }

    &.backup-restore-empty:before {
        content: $icon-backup-restore-empty;
    }

    &.bookmark-empty:before {
        content: $icon-bookmark-empty;
    }

    &.bookmark-filled:before {
        content: $icon-bookmark-filled;
    }

    &.checkbox-checked-filled:before {
        content: $icon-checkbox-checked-filled;
    }

    &.checkbox-unchecked-empty:before {
        content: $icon-checkbox-unchecked-empty;
    }

    &.check-circle-empty:before {
        content: $icon-check-circle-empty;
    }

    &.check-circle-filled {
        &.icon-after-label:after {
            content: $icon-check-circle-filled;
        }

        &:not(.icon-after-label):before {
            content: $icon-check-circle-filled;
        }
    }

    &.check-done:before {
        content: $icon-check-done;
    }

    &.circle-exclamation-filled {
        &.icon-after-label:after {
            content: $icon-circle-exclamation-filled;
        }

        &:not(.icon-after-label):before {
            content: $icon-circle-exclamation-filled;
        }
    }

    &.circle-radio-button-checked-filled:before {
        content: $icon-circle-radio-button-checked-filled;
    }

    &.circle-radio-button-unchecked-empty:before {
        content: $icon-circle-radio-button-unchecked-empty;
    }

    &.clear-circle {
        &:after {
            content: $icon-cancel-clear-circle-highlight-off-empty;
        }

        &:hover:after {
            content: $icon-cancel-clear-circle-highlight-off-filled;
        }
    }

    &.clear-close:before {
        content: $icon-clear-close;
    }

    &.control-point-duplicate-empty:before {
        content: $icon-control-point-duplicate-empty;
    }

    &.copy-link:before {
        content: $icon-link;
    }

    &.cut-scissors-filled:before {
        content: $icon-cut-scissors-filled;
    }

    &.delete-trash-empty:before {
        content: $icon-delete-trash-empty;
    }

    &.delete-trash-filled:before {
        content: $icon-delete-trash-filled;
    }

    &.document-text:before {
        content: $icon-document-text;
    }

    &.do-not-disturb-on-remove-circle-empty {
        &.icon-after-label:after {
            content: $icon-do-not-disturb-on-remove-circle-empty;
        }

        &:not(.icon-after-label):before {
            content: $icon-do-not-disturb-on-remove-circle-empty;
        }
    }

    &.download:before {
        content: $icon-download;
    }

    &.edit-empty:before {
        content: $icon-create-empty;
    }

    &.exit-door-empty:before {
        content: $icon-exit-door-empty;
    }

    &.eye-visibility-empty:before {
        content: $icon-eye-visibility-empty;
    }

    &.hub-empty:before {
        content: $icon-hub-empty;
    }

    &.help-empty:before {
        content: $icon-help-empty;
    }

    &.help-filled:before {
        content: $icon-help-filled;
    }

    &.info-empty:before {
        content: $icon-info-empty;
    }

    &.open-new-window {
        &.icon-after-label:after {
            content: $icon-open-new-window;
        }

        &:not(.icon-after-label):before {
            content: $icon-open-new-window;
        }
    }

    &.play-in-circle-filled:before {
        content: $icon-play-in-circle-filled;
    }

    &.plus:before {
        content: $icon-plus;
    }

    &.print:before {
        content: $icon-print;
    }

    &.search:before {
        content: $icon-search;
    }

    &.settings-applications-empty:before {
        content: $icon-settings-applications-empty;
    }

    &.shevron-in-circle-up-filled:after {
        content: $icon-shevron-in-circle-up-filled;
    }

    &.shevron-keyboard-arrow-left:before {
        content: $icon-shevron-keyboard-arrow-left;
    }

    &.shevron-keyboard-arrow-right:before {
        content: $icon-shevron-keyboard-arrow-right;
    }

    &.shevron-small-down-expand-more:after {
        content: $icon-shevron-small-down-expand-more;
    }

    &.shevron-small-up-expand-less:before {
        content: $icon-shevron-small-up-expand-less;
    }

    &.swap-vertical:before {
        content: $icon-swap-vertical;
    }

    &.update-refresh:before {
        content: $icon-update-refresh;
    }

    &.view-module-empty:before {
        content: $icon-view-module-empty;
    }

    &.view-stream-view-agenda-empty {
        @include rotate(90deg);

        &:before {
            content: $icon-view-stream-view-agenda-empty;
        }
    }

    &.view-stream-view-agenda-filled {
        @include rotate(90deg);

        &:before {
            content: $icon-view-stream-view-agenda-filled;
        }
    }

    &.warning-report-problem-empty {
        &.icon-after-label:after {
            content: $icon-warning-report-problem-empty;
        }

        &:not(.icon-after-label):before {
            content: $icon-warning-report-problem-empty;
        }
    }

    &.warning-report-problem-filled {
        &.icon-after-label:after {
            content: $icon-warning-report-problem-filled;
        }

        &:not(.icon-after-label):before {
            content: $icon-warning-report-problem-filled;
        }
    }
}
