@import "~assets/sass/_mixins";

.form-wrap {
    &__vlm {
        .form-wrap-main-container {
            flex: 65%;

            .logo {
                background-image: url("https://files.insight.visiondsm.com/public/Vision-Horizontal-LoadMAP-Blue.svg");
            }

            .login-form,
            .reset-password-form,
            .qr-code-form,
            .forgot-password-form,
            .change-password-form {
                &__header {
                    text-align: left;
                }
            }
        }

        .form-wrap-side-container {
            flex: 35%;
        }
    }

    &__container {
        min-height: 100%;
    }
}
