.Toastify__toast-theme--colored.Toastify__toast--success {
    background: var(--ids-semantic-background-color-success-subtlest-default);
    color: var(--ids-semantic-ink-color-neutral-accent-onlight);

    .Toastify__toast-icon > svg {
        fill: var(--ids-semantic-background-color-success-subtle-active);
    }

    .Toastify__close-button > svg {
        fill: var(--ids-semantic-ink-color-neutral-accent-onlight);
    }

    .Toastify__progress-bar {
        background: var(--ids-semantic-background-color-success-subtle-active);
    }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    background: var(--ids-semantic-background-color-critical-subtlest-default);
    color: var(--ids-semantic-ink-color-neutral-accent-onlight);

    .Toastify__toast-icon > svg {
        fill: var(--ids-semantic-background-color-critical-accent-default);
    }

    .Toastify__close-button > svg {
        fill: var(--ids-semantic-ink-color-neutral-accent-onlight);
    }

    .Toastify__progress-bar {
        background: var(--ids-semantic-background-color-critical-accent-default);
    }
}
