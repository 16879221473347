@import "~assets/sass/_mixins";

.custom-list {
    position: relative;

    &:not(.no-padding) {
        .list-header-row .column-header,
        .list-item-row .item-value {
            &:first-child {
                padding-left: 15px;
            }

            &:last-child {
                padding-right: 15px;
            }
        }
    }

    .list-header-row,
    .list-filter,
    .list-item-row {
        min-width: fit-content;
    }

    .list-header-row {
        font-weight: 700;
        font-size: pxToRem(12);
        text-transform: uppercase;

        @include themify($themes) {
            background-color: themed("primaryBackgroundColor");
        }

        .column-header {
            height: 44px;
            text-overflow: ellipsis;
        }

        .sortable {
            cursor: pointer;

            @at-root .last-col-to-right#{&} {
                &:last-child {
                    text-align: right;
                }
            }

            &:hover {
                .column-content > .swap-vertical {
                    display: inline-block;
                }
            }
        }

        .column-content {
            display: flex;
            align-items: center;
            width: 100%;

            .column-name {
                font-weight: 500;
                white-space: nowrap;

                @include text-overflow;
            }

            > .icon-wrap.swap-vertical {
                display: none;
            }

            .sorted {
                position: relative;

                @include themify($themes) {
                    color: themed("primaryColor");
                }

                &.asc .icon-wrap.swap-vertical {
                    transform: scaleX(-1);
                    top: -2px;
                }

                &.desc .icon-wrap.swap-vertical {
                    left: -1px;
                }
            }

            .column-sorting-hint {
                position: absolute;
                right: 5px;
                top: 10px;
                word-break: break-all;
                width: 9px;
                padding-left: 2px;
                background: $gray-100;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 6px;
                line-height: 1.1;

                @include themify($themes) {
                    color: themed("primaryColor");
                }
            }
        }
    }

    &.clickable-rows .list-item-row {
        cursor: pointer;
    }

    .list-filter {
        border-bottom: 1px solid $grey-light;
        padding-bottom: 10px;
    }

    .list-item-row {
        border-bottom: 1px solid $grey-light;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        height: 44px;
        font-size: pxToRem(14);

        &:hover {
            @include themify($themes) {
                background-color: themed("primaryBackgroundColor");
            }

            .show-on-hover {
                opacity: initial;
            }
        }

        &.selected {
            @include themify($themes) {
                background: themed("primaryBackgroundColor");
            }
        }

        .item-value {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: break-word;

            &.text-left {
                text-align: left;
            }

            &.text-right {
                text-align: right;
            }

            &.fit-content {
                width: fit-content;
            }

            @at-root .last-col-to-right#{&} {
                &:last-child {
                    text-align: right;
                }
            }
        }

        .show-on-hover {
            opacity: 0;

            &:focus {
                opacity: initial;
            }
        }
    }

    .list-search-headers-row {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .column-filter {
            width: 100%;
            overflow: visible;
            text-overflow: ellipsis;
            overflow-wrap: break-word;

            @at-root .last-col-to-right#{&} {
                &:last-child {
                    text-align: right;
                }
            }

            &.filter {
                height: 100%;

                &:not(.noborder) {
                    &:focus-within,
                    &.active {
                        z-index: $fade-z-index + 1;
                    }

                    &:not(:focus-within):not(.active) {
                        border-top: 2px solid $grey-light;
                        border-bottom: 2px solid $grey-light;

                        & + *:not(:focus-within):not(.active) {
                            border-left: 1px solid $grey-light;
                        }

                        &.left:not(:focus-within):not(.active) {
                            border-left: 2px solid $grey-light;
                        }

                        &.right:not(:focus-within):not(.active) {
                            border-right: 2px solid $grey-light;
                        }
                    }

                    &:focus-within,
                    &.active {
                        @include themify($themes) {
                            border: 2px solid themed("selectionColor");
                        }
                    }
                }

                &.hidden {
                    visibility: hidden;
                    pointer-events: none;
                }
            }
        }
    }

    .not-found-msg {
        padding: 15px;
    }

    .scroll-body.list-body {
        flex: 1;
        overflow: auto;

        @include ie {
            flex: 1 0 auto;
        }
    }

    &.scroll-body {
        overflow: hidden;
    }

    .with-fade {
        @include withFade($top: 0);
    }

    .wrap-freeze-items {
        border-left: 1px solid $grey-light;
        border-right: 1px solid $grey-light;
        flex: 1;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .wrap-scroll-items {
        border-right: 1px solid $grey-light;
        flex: 1;
        overflow-x: auto;
        overflow-y: hidden;
    }

    &.pagination-align-right {
        justify-content: flex-end;
        padding: 0 13px 0 0;
    }
}
