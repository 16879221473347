@import "~assets/sass/_mixins";

.step-box {
    background-color: var(--ids-semantic-background-color-neutral-accent-default-onlight);
    color: var(--ids-semantic-ink-color-neutral-accent-onlight);

    border-radius: 8px;
    min-width: 32px;
    padding: 5px;

    @include semanticFontFixedHeading5Bold;
}
