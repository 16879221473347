$standalone-report-list-column-width: (
    report-type: 100px,
);

$standalone-report-list-column-padding: 8px;

.standalone-report-list {
    .column {
        @each $column, $size in $standalone-report-list-column-width {
            &-#{$column} {
                max-width: $size;
            }
        }

        &-report-type,
        &-last-update {
            padding-left: $standalone-report-list-column-padding;
        }

        &-filter {
            padding: 0;
        }
    }
}
