@import "~assets/sass/_variables";
@import "~assets/sass/_mixins";

.study-case {
    &__tables {
        .section-title {
            font-size: pxToRem(18);

            &__margin {
                margin: 40px 0 0 0;
            }
        }

        .section-title-separator {
            margin-top: 8px;
        }

        .column-content {
            padding: 0 60px 0 0;

            @include calc(width, "100% / 3");

            .row-value {
                &.row-value-changed {
                    border-radius: 3px;
                    background-color: $yellow-white;
                }

                .row-title {
                    font-weight: 500;
                }

                .icon-with-label {
                    &.with-icon:before {
                        @include themify($themes) {
                            color: themed("primaryColor");
                        }
                    }
                }
            }
        }
    }

    &__warning {
        background-color: $yellow-white;
        padding: 3px 10px;
        margin: 20px 0 0 0;
        border-radius: 10px;
    }

    &__actions {
        padding: 0 0 8px 0;
        margin: 20px 0 0 0;
    }
}
