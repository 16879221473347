@import "~assets/sass/_mixins";

.tab-list {
    position: relative;
    width: 100%;

    &.tab-list-ghost {
        border-bottom: 1px solid $grey-light;

        .tabs {
            position: relative;
            top: 2px;

            .tab {
                padding: 0;
                min-height: 30px;

                &:not(:last-child) {
                    margin-right: 30px;
                }

                &.active {
                    background: transparent;
                }
            }
        }
    }

    &.tab-list-ghost-without-border {
        border: none;
    }

    .tabs {
        overflow: hidden;

        .tab {
            border-bottom: 2px solid transparent;
            min-height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: pxToRem(14);
            text-transform: uppercase;
            padding: 7px 20px;
            cursor: pointer;

            @include ie {
                flex-shrink: 0;
            }

            &:not(.equal-width) {
                white-space: nowrap;
            }

            &:hover {
                @include themify($themes) {
                    color: themed("primaryColor");
                }
            }

            &.active {
                background-color: $white;

                @include themify($themes) {
                    border-bottom: 2px solid themed("primaryColor");
                    color: themed("primaryColor");
                }
            }

            &:not(.active) {
                background-color: transparent;
            }

            &.equal-width {
                width: 50%;

                &:not(.active) {
                    background-color: $grey-white;
                }
            }

            &.error {
                color: $red-medium;
            }
        }
    }

    .scroll-controls {
        z-index: inherit;
        top: auto;
        height: auto;

        .main-grid-wrap {
            padding: 0;
            min-width: inherit;
            max-width: inherit;
        }

        .scroll-control {
            position: relative;
            top: -44px;
            width: 64px;

            .icon-wrap {
                position: initial;
                width: 30px;
                height: 44px;
                line-height: 44px;
                font-size: 18px;
            }

            &.left {
                background: linear-gradient(to left, rgba(0, 0, 0, 0), #f5f7fa 30%);
                float: left;
                left: -8px;
                text-align: left;
            }

            &.right {
                background: linear-gradient(to right, rgba(0, 0, 0, 0), #f5f7fa 30%);
                float: right;
                right: -8px;
                text-align: right;

                .icon-wrap {
                    text-align: right;
                    right: auto;
                }
            }
        }
    }
}
