.import-error-panel {
    &__error-message {
        padding-bottom: 5px;
        padding-left: 35px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}
