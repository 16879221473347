@import "~assets/sass/_mixins";

.standalone-report-dashboard-header {
    background-color: $white;
    padding: 50px 30px 15px 30px;

    @include box-shadow-common($shadow-emboss);

    &__top-container {
        margin-bottom: 10px;
    }

    &__model-name {
        font-size: pxToRem(18);
    }

    &__model-details {
        font-size: pxToRem(14);
    }

    &__label {
        color: var(--ids-semantic-ink-color-neutral-subtle-onlight);
    }

    &__title {
        font-weight: 500;
        font-size: pxToRem(21);
        line-height: 24px;
    }
}
