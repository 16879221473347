.cards-container {
    border: 1px solid var(--ids-semantic-border-color-neutral-faint);
    background-color: var(--ids-semantic-background-color-neutral-accent-default-onlight);

    overflow: hidden;

    + .cards-container {
        margin-top: 40px;
    }

    .card + .card {
        margin-top: 10px;
    }

    &.cards-container-add-card {
        border: 1px dashed var(--ids-semantic-border-color-brand-a-subtle);

        border-radius: 8px;
        background: none;

        .card {
            background: none;
        }
    }

    &:not(.cards-container-add-card) {
        .card {
            &:first-child {
                border-radius: 0;

                &:not(.selected) {
                    border-top: 1px solid transparent;
                    border-right: 1px solid transparent;
                    border-left: 1px solid transparent;
                }
            }
        }
    }
}
