@import "~assets/sass/_mixins";

.paging-wrap {
    padding: 12px 0 12px 36px;
}

.selectable-page {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 18px;
    min-width: 18px;
    line-height: 18px;
    user-select: none;
    border-radius: 18px;

    &:not(.spacer) {
        cursor: pointer;
    }

    &.active {
        pointer-events: none;

        @include themify($themes) {
            background-color: themed("primaryColor");
            color: $white;
        }
    }

    span {
        font-weight: 700;
        font-size: pxToRem(12);

        @include themify($themes) {
            color: themed("primaryColor");
        }
    }

    &.active span {
        @include themify($themes) {
            color: $white;
        }
    }
}
