.interweave-markup {
    ul,
    ol {
        padding: 0 25px;
    }

    ul {
        list-style: disc;
    }
}
